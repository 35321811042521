import { render, staticRenderFns } from "./CarrierRhenus.vue?vue&type=template&id=6d8272c0&scoped=true&"
import script from "./CarrierRhenus.vue?vue&type=script&lang=js&"
export * from "./CarrierRhenus.vue?vue&type=script&lang=js&"
import style0 from "./CarrierRhenus.vue?vue&type=style&index=0&id=6d8272c0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8272c0",
  null
  
)

export default component.exports