<template>
    <div>
        <div class="box">
            <div class="box-header">
                <h6 class="title-color inline">Pakowanie <span v-if="Array.isArray(order.boxes) && order.boxes.length" class="label text-white m-r-xs none-radius inline c-green">Łącznie {{ order.boxes.length }}szt. kartonów</span></h6>
                (
                <span class="text-yellow">FedEx: {{ totalBoxesPrices.fedex.v | money }} <i v-if="totalBoxesPrices.fedex.notFull" class="material-icons md-16 text-red">error</i></span> /&nbsp;
                <span class="text-green">DPD: {{ totalBoxesPrices.dpd.v | money }} <i v-if="totalBoxesPrices.dpd.notFull" class="material-icons md-16 text-red">error</i></span> /&nbsp;
                <span class="text-red">Poczta: {{ totalBoxesPrices.pp.v | money }} <i v-if="totalBoxesPrices.pp.notFull" class="material-icons md-16 text-red">error</i></span> /&nbsp;
                <span class="text-warning">InpostPacz.: {{ totalBoxesPrices.inpost_paczkomat.v | money }} <i v-if="totalBoxesPrices.inpost_paczkomat.notFull" class="material-icons md-16 text-red">error</i></span>
                )
                <div class="pull-right" v-if="this.selected.length > 0">
                    <a class="text-blue text-sm" @click.prevent="deliveryShip">
                        <i class="material-icons md-18">send</i>
                        Nadaj przesyłkę
                    </a>
                    <span class="text-blue">|</span>
                    <a class="text-blue text-sm" @click.prevent="addShip">
                        <i class="material-icons md-18">add</i>
                        Dodaj przesyłkę
                    </a>
                </div>
            </div>
            <div class="m-a-0 custom-divider"/>
            <div class="m-l">
                <a class="text-blue text-sm" @click.prevent="addAll" v-if="order.boxes && order.boxes.length > 0">
                    Wybierz wszystkie
                </a>
                <span class="text-blue"> | </span>
                <!-- @todo -->
                <a class="text-blue text-sm" @click.prevent="addBox">
                    Dodaj karton
                </a>
            </div>
            <div class="box-body row">
                <div class="col-lg-6" v-for="box in boxes" :key="box._id">
                    <div class="order-box">
                        <div class="p-y-sm p-x-sm" style="position:relative;">
                            <div>

                                <label class="md-check p-r-sm" v-if="!box.shipmentId">
                                    <input type="checkbox" v-model="selected" :value="box._id">
                                    <i class="blue m-r-xs "/>
                                </label>

                                <a class="pull-right text-xs m-l-sm" @click="removeBox(box)">
                                    <i class="material-icons text-red md-18">cancel</i>
                                </a>
                                <a class="pull-right text-xs" @click="print(box)">
                                    <i class="material-icons md-18">print</i>
                                </a>

                                <img v-if="box.isStretchFoil" class="icon m-r-sm" src="../../assets/images/foil.png">
                                <img v-else class="icon m-r-sm" src="../../assets/images/box-color.png">
                                <span v-if="box.isStretchFoil">Folia:</span>
                                <span v-else>Karton:</span>
                                <span class="_600 text-u-c" >{{box.code}}</span><br>
                            </div>

                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="text-xs m-t-sm">
                                        <span class="text-muted" v-if="box._createdAt">Utworzono {{ box._createdAt | moment('DD/MM o go\\d\\z. HH:mm') }}<br></span>
                                        <span class="text-muted" v-if="box.width && box.height">Wymiary: {{ box.width }}mm x {{ box.height }}mm x {{ box.length }}mm<br></span>
                                        <span class="text-muted" v-if="box.mass">Waga: {{ box.mass }}kg<br></span>
                                        <span class="text-muted" v-if="box.employee">Spakował: {{ box.employee.name }}<br></span>
                                        <span class="text-muted" v-if="box.mass && !box.isStretchFoil">Łącznie w kartonie: {{ getProductSumQty(box) }}szt<br></span>
                                        <span class="text-muted" v-if="box.mass && box.isStretchFoil">Łącznie: {{ getProductSumQty(box) }}szt<br></span>

                                        <span class="text-muted" v-if="box.ship">
                                            Przesyłka: {{ box.ship.parcelNo }}
                                            <a v-if="!box.ship.handedOver" class="text-xs text-blue" @click.prevent="removeShipmentFromBox(box)">Usuń powiązanie</a>
                                            <span v-else>
                                                <span v-if="box.ship.status == 'sent'" class="text-yellow m-l-xs"><i class="material-icons md-18">check</i> Wysłane</span>
                                                <span v-else-if="box.ship.status == 'delivered'" class="text-green m-l-xs"><i class="material-icons md-18">check</i> Doręczono</span>
                                                <span v-else-if="box.ship.status == 'delivery-problems'" class="text-red m-l-xs"><i class="material-icons md-18">close</i> Problemy</span>
                                            </span>
                                            <br>
                                        </span>

                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="text-xs m-t-sm" v-if="box.prices">
                                        <span style="text-decoration: underline;">Szacowany koszt:<br></span>
                                        <span class="text-muted">FedEx: {{ box.prices.fedex | money }}<br></span>
                                        <span class="text-muted">DPD: {{ box.prices.dpd | money }}<br></span>
                                        <span class="text-muted">Poczta: {{ box.prices.pp | money }}<br></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6" v-for="pallet in order.shippingPalletes" :key="pallet._id">
                    <div class="order-box">
                        <div class="p-y-sm p-x" style="position:relative;">
                            <div>
                                <label class="md-check p-r-sm" v-if="!pallet.shipmentId">
                                    <input type="checkbox" v-model="selected" :value="pallet._id">
                                    <i class="blue m-r-xs "/>
                                </label>
                                <a class="pull-right text-xs" @click="printPallet(pallet)">
                                    <i class="material-icons md-18">print</i>
                                </a>
                                <img class="icon m-r-sm" src="../../assets/images/pallet.png">
                                Paleta: <span class="text-xs text-muted text-u-c" ><span class="label m-r-xs inline c-green text-black" v-for="boxCode in pallet.boxes" :key="boxCode">{{ boxCode }}</span></span><br>
                            </div>
                            <div class="text-xs m-t-sm">
                                <span class="text-muted" v-if="pallet._createdAt">Utworzono {{ pallet._createdAt | moment('DD/MM o go\\d\\z. HH:mm') }}<br></span>
                                <span class="text-muted" v-if="pallet.width && pallet.height">Wymiary: {{ pallet.width }}mm x {{ pallet.length }}mm x {{ pallet.height }}mm<br></span>
                                <span class="text-muted" v-if="pallet.mass">Waga: {{ pallet.mass }}kg<br></span>
                                <span class="text-muted" v-if="pallet.packedBy">Spakował: {{ getEmployeName(pallet.packedBy) }}<br></span>
                                <span class="text-muted" v-if="pallet.boxes && pallet.boxes.length > 0">Kartonów na palecie: {{ pallet.boxes.length }}szt<br></span>
                                <span class="text-muted" v-if="pallet.shipmentId && shipments.find(s => s._id == pallet.shipmentId)">
                                    Przesyłka: {{ shipments.find(s => s._id == pallet.shipmentId).parcelNo }}
                                    <a v-if="!shipments.find(s => s._id == pallet.shipmentId).handedOver"  class="text-xs text-blue" @click.prevent="removeShipmentFromBox(pallet)">Usuń powiązanie</a>
                                    <span v-else>
                                        <span v-if="shipments.find(s => s._id == pallet.shipmentId).status == 'sent'" class="text-yellow m-l-xs"><i class="material-icons md-18">check</i> Wysłane</span>
                                        <span v-else-if="shipments.find(s => s._id == pallet.shipmentId).status == 'delivered'" class="text-green m-l-xs"><i class="material-icons md-18">check</i> Doręczono</span>
                                        <span v-else-if="shipments.find(s => s._id == pallet.shipmentId).status == 'delivery-problems'" class="text-red m-l-xs"><i class="material-icons md-18">close</i> Problemy</span>
                                    </span>
                                    <br>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import { getBoxSize } from '../../Common';
import EmployeeManager from '../../EmployeeManager.js';
import AddShipmentModal from './Modals/AddShipmentModal.vue';
import AddBoxToOrderModal from './Modals/AddBoxToOrderModal.vue';
import EventBus from '../../event-bus.js';

export default {
    name: 'Boxes',
    props: {
        order: Object
    },

    components: {
    },

    data: function() {
        return {
            employees: [],
            shipmentsSettings: null,
            selected: [],
            shipments: []
        }
    },

    created: async function() {
        this.employees = await EmployeeManager.getEmployees();
        this.shipmentsSettings = await Api.shipments.getCofiguration();
        if (this.order) this.shipments = await Api.shipments.search({ order: this.order._id });
    },

    destroyed: function() {
        EventBus.$off('orderShipmentCreated', this.orderShipmentCreated);
    },

    computed: {
        boxes() {
            const results = [];
            for (const box of this.order.boxes || []) {
                const b = {
                    employee: this.employees.find(emp => emp._id == box.packedBy),
                    prices: this.calculatePricesTable(box),
                    ship: box.shipmentId ? this.shipments.find(s => s._id == box.shipmentId) : null
                };
                Object.assign(b, box)
                results.push(b);
            }
            return results;
        },

        totalBoxesPrices() {
            const prices = {
                pp: { v: 0, notFull: false },
                fedex: { v: 0, notFull: false },
                dpd: { v: 0, notFull: false },
                inpost_paczkomat: { v: 0, notFull: false }
            };

            for (const box of this.boxes) {
                if (!box.prices) continue;
                for (const k of Object.keys(prices)) {
                    prices[k].v += box.prices[k];
                    if (!box.prices[k]) prices[k].notFull = true;
                }
            }

            return prices;
        }
    },

    methods: {
        orderShipmentCreated(shipment) {
            this.shipments.push(shipment);
        },

        getShipNumber(id) {
            let parcelNo = null;
            for (let i = 0; i < this.shipments.length; i++) {
                const ship = this.shipments[i];
                if (ship._id == id) parcelNo = ship.parcelNo;
            }
            return parcelNo;
        },
        calculatePricesTable(box) {
            if (!this.shipmentsSettings) return null;

            const prices = {
                pp: 0,
                fedex: 0,
                dpd: 0,
                inpost_paczkomat: 0
            };

            const ppGabaryt = getBoxSize(box); // moze byc null dla wiekszych przesylek!
            if (ppGabaryt && this.shipmentsSettings.pp.pricesMap) {
                prices.pp = this.shipmentsSettings.pp.pricesMap[ppGabaryt] || 0;
            }

            if (this.shipmentsSettings.fedex && Array.isArray(this.shipmentsSettings.fedex.priceRanges)) {
                const volumeWeightFedEx = (box.width * box.height * box.length) / this.shipmentsSettings.fedex.volumeWeightFactor;
                const fedexPrices = this.shipmentsSettings.fedex.priceRanges
                    .filter(pr => volumeWeightFedEx <= pr.maxWeight)
                    .sort((a, b) => a.price - b.price);

                if (fedexPrices.length > 0) prices.fedex = fedexPrices[0].price;
            }

            if (this.shipmentsSettings.dpd && Array.isArray(this.shipmentsSettings.dpd.priceRanges)) {
                const volumeWeightDPD = (box.width * box.height * box.length) / this.shipmentsSettings.dpd.volumeWeightFactor;
                const dpdPrices = this.shipmentsSettings.dpd.priceRanges
                    .filter(pr => volumeWeightDPD <= pr.maxWeight)
                    .sort((a, b) => a.price - b.price);

                if (dpdPrices.length > 0) prices.dpd = dpdPrices[0].price;
            }

            if (this.shipmentsSettings.inpost_paczkomat) {
                const pricePerSize = this.shipmentsSettings.inpost_paczkomat.pricesPerSize;
                for (const variant of pricePerSize.sort((a, b) => a.price - b.price)) {
                    const boxSizes = [box.width, box.height, box.length].sort((a, b) => a - b);
                    if (boxSizes[0] <= variant.width * 10 && boxSizes[1] <= variant.height * 10 && boxSizes[2] <= variant.length * 10) {
                        prices.inpost_paczkomat = variant.price;
                        break;
                    }
                }
            }

            return prices;
        },

        getEmployeName: function(empId) {
            const emp = this.employees.find(emp => emp._id == empId);
            if (!emp) return "- - -";
            return emp.name;
        },

        getProductSumQty: function(box) {
            let qty = 0;
            if (!Array.isArray(box.contents)) return qty;

            for (let i = 0; i < box.contents.length; i++) {
                qty += box.contents[i].qty;
            }
            return qty;
        },

        async printPallet(pallet) {
            if (!this.order || !this.order.production.palletesPrintTemplateId) {
                this.$helpers.errorNotif('Brakuje wybranego szanblonu wydruku dla zamowienia');
                return;
            }

            this.$helpers.info("Trwa generowanie wydruku...");
            const res = await Api.printing.prepare(this.order.production.palletesPrintTemplateId, {
                orderId: this.order._id,
                palletId: pallet._id
            });
            this.$helpers.success("Wydruk gotowy");

            const popup = window.open(Api.printing.getPreparedPrintUrl(res.printKey), "Etykieta paletowa", 'height=800,width=800');
            if (popup && window.focus) popup.focus();
        },

        async print(box) {
            if (!this.order || !this.order.production.labelsPrintTemplateId) {
                this.$helpers.errorNotif('Brakuje wybranego szanblonu wydruku dla zamowienia');
                return;
            }

            this.$helpers.info("Trwa generowanie wydruku...");
            const res = await Api.printing.prepare(this.order.production.labelsPrintTemplateId, {
                orderId: this.order._id,
                boxCode: box.code
            });
            this.$helpers.success("Wydruk gotowy");

            const popup = window.open(Api.printing.getPreparedPrintUrl(res.printKey), "Etykieta zbiorcza", 'height=800,width=800');
            if (popup && window.focus) popup.focus();
        },
        deliveryShip() {
            this.$router.push({ name: 'add-shipment', params: { orderId: this.order._id, selectedBoxAndPallets: this.selected } })
        },
        addShip() {
            this.$modal.show(AddShipmentModal, {
                order: this.order,
                selected: this.selected
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        },
        addAll() {
            this.selected.length = 0;
            let idsPalette = [];
            let idsBox = [];
            if (this.order.shippingPalletes) idsPalette = this.order.shippingPalletes.filter(p => p.shipmentId == null).map(p => p._id);
            if (this.boxes) idsBox = this.boxes.filter(p => p.shipmentId == null).map(p => p._id);

            this.selected.push(...idsPalette);
            this.selected.push(...idsBox);
        },
        async removeShipmentFromBox(boxOrPallete) {
            const c = await this.$helpers.confirm(`Czy na pewno chesz wykonać tą akcje?`);
            if (!c) return;

            // Jeśli posada kod to jest to karton
            if (boxOrPallete.code != null) await Api.order.editBox(this.order._id, boxOrPallete.code, { shipmentId: null });
            else await Api.order.editPalette(this.order._id, boxOrPallete._id, { shipmentId: null });

            boxOrPallete.shipmentId = null;
        },
        addBox() {
            this.$modal.show(AddBoxToOrderModal, {
                order: this.order
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        },
        async removeBox(box) {
            const c = await this.$helpers.confirm(`Czy na pewno chesz usunąć wybrany karton?`);
            if (!c) return;

            await Api.order.removeBox(this.order._id, box.code);
        }
    }
}
</script>
<style scoped lang="scss">
.order-box {
    margin-bottom: 15px;
    background-color: #fafbfc;
    // cursor: pointer;
    border: 1px solid rgba(120, 130, 140, 0.13);
}

.icon {
  width: 25px;
  height: auto;
}
</style>
