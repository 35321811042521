<template>
    <div class="row">
        <div class="box">
            <div class="box-header d-gray">
                <h6 class="text-muted _400 inline">Ustawienia</h6>
            </div>
            <div class="box-divider m-a-0"/>
            <div class="l-gray p-b">
                <ul class="btn-group nav nav-pills p-t p-l">
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/prefernces">
                            <span class="_400">Preferencje</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/users">
                            <span class="_400">Użytkownicy</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/devices">
                            <span class="_400">Urządzenia</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/shipments">
                            <span class="_400">Przesyłki</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/mailer">
                            <span class="_400">Ustawienia wiadomości</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/entities">
                            <span class="_400">Encje systemowe</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/system">
                            <span class="_400">System</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/settings/automation">
                            <span class="_400">Automatyzacje</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="box-divider m-a-0"/>
            <div class="tab-content m-b-md">
                <div class="tab-pane animated fadeIn active text-muted">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Settings',
    components: {

    }
}
</script>
