<template>
    <div class="box m-b-0 box-modal">
        <div class="box-header">
            <h6 class="_400 inline">Zmień status zamówienia</h6>
            <small v-if="order">{{ order.name }}</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing" style="min-height:270px">
            <form role="form">
                <div class="form-group row" >
                    <label class="col-sm-3  form-control-label">Nowy status: </label>
                    <div class="col-sm-9 ">
                        <v-select :options="statusesOptions" label="text" :reduce="r => r.id" v-model="selectedStatus" />
                    </div>
                </div>
            </form>

            <div v-if="selectedStatus == 'errored'">
                <span>Podaj przyczynę zmiany statusu na błędny:</span>
                <div class="form-group row" >
                    <div class="col-md-12">
                        <textarea class="form-control" rows="3" v-model="note"/>
                    </div>
                </div>

            </div>

            <div v-if="statusesHistory.length" style="border-top: 1px solid #eee; padding-top: 15px;">
                <strong>Historia zamówienia: </strong>
                <div v-for="sh in statusesHistory" :key="sh.time" class="p-a-xs">
                    <span>{{ sh.time| moment('from') }}</span> &gt;
                    <OrderStatusSpan :statusCode="sh.code" />
                    przez {{ sh.author }}
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer clearfix" v-else>
            <button
                class="btn white none-radius btn-sm"
                :disabled="isProcessing"
                @click="$emit('close')">Anuluj</button>

            <button
                class="pull-right btn info none-radius btn-sm"
                :disabled="isProcessing"
                @click="saveAndExit">Zapisz</button>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import OrderStatusSpan from '../OrderStatusSpan.vue';
import EmployeeManager from '../../../EmployeeManager.js';
import SystemUsersManager from '../../../SystemUsersManager.js';
import { ORDER_STATUSES_MAP } from '../../../Common.js';

export default {
    name: 'ChangeOrderStatusModal',
    props: {
        order: Object
    },
    components: {
        RotateSquare2,
        OrderStatusSpan
    },
    data: function () {
        return {
            employees: [],
            systemUsers: [],
            selectedStatus: null,
            isProcessing: false,
            note: "Notatka do błędnego statusu: "
        }
    },
    created: async function() {
        this.employees = await EmployeeManager.getEmployees();
        this.systemUsers = await SystemUsersManager.getSystemUsers();
    },

    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        statusesOptions: function() {
            const o = [];
            for (const code in ORDER_STATUSES_MAP) {
                if (this.order.status == code) continue;
                o.push({ id: code, text: ORDER_STATUSES_MAP[code] });
            }
            return o;
        },

        statusesHistory: function() {
            if (!this.order || !Array.isArray(this.order.statusHistory)) return [];

            const history = [];
            for (let i = 0; i < this.order.statusHistory.length; i++) {
                const sh = this.order.statusHistory[i];

                let author = "System";
                if (sh.author == "user") {
                    const usr = this.systemUsers.find(e => e._id == sh.userId);
                    author = usr ? usr.name : "<< Brak użytykownika >>";
                }
                if (sh.author == "employee") {
                    const emp = this.employees.find(e => e._id == sh.userId);
                    author = emp ? (emp.name + " (pracownik)") : "<< Brak pracownika >>";
                };

                history.push({
                    code: sh.code,
                    time: new Date(sh.time),
                    author
                });
            }

            return history.sort((a, b) => { return b.time - a.time });
        }
    },
    methods: {

        saveAndExit: async function() {
            this.isProcessing = true;
            await Api.order.setStatus(this.order._id, this.selectedStatus);
            this.order.status = this.selectedStatus;
            //jeśli status jest będny to dodajemy notatkę dlaczego błedny
            if(this.selectedStatus == 'errored') {
                const notes = await Api.order.addNote(this.order._id, { content: this.note, isProductionNote: false });
            }
            this.isProcessing = false;
            this.$emit('close', true);
        },

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
