<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Notatki</h6>
            <a href="#" class="pull-right text-blue text-sm m-t" @click.prevent="openNotesModal">Dodaj notatke</a>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <ul class="list inset text-color" v-if="notes.length > 0">
                <li class="list-item p-x-xs" v-for="n in sortedNotes" :key="n._id">
                    <!-- Data i nazwisko tego kto wystawił komentarz -->
                    <small class="block text-muted m-b-sm">
                        <span>{{ n.authorName }}</span>
                        <a v-if="n.userId == userId" class="nav-link primary-color m-l-xs" title="Usuń notatkę" @click="remove(n._id)" :disabled="procesing">
                            <i class="material-icons md-16 text-red">close</i>
                        </a>
                        <span class="pull-right">
                            <span v-if="n.created">{{n.created | moment("DD MMM YYYY | HH:mm")}}</span>
                        </span>
                    </small>
                    <div class="content-comment p-y-sm p-x-sm primary-color">
                        <nl2br tag="span" :text="n.content" />
                        <p class="text-xs text-blue m-b-0 m-t-xs" v-if="n.isProductionNote"><i class="material-icons md-18">check</i> Notatka przekazana na produkcję</p>
                    </div>
                </li>
            </ul>
            <span class="text-muted" v-else>Na razie brak notatek do tego zamówienia</span>
        </div>
    </div>
</template>

<script>
import UserManager from '../../UserManager.js';
import SystemUsersManager from '../../SystemUsersManager.js';
import GenericModal from '../GenericModal.vue';
import Api from '../../Api.js';
import Nl2br from 'vue-nl2br';

export default {
    name: 'Notes',
    props: {
        notes: Array,
        orderId: String
    },
    components: {
        Nl2br
    },
    data: function () {
        return {
            procesing: false,
            userId: null,
            systemUsers: []
        }
    },
    computed: {
        sortedNotes: function () {
            return [...this.notes].map(n => {
                let authorName = 'Komentarz systemowy';
                if (n.author == 'user') {
                    const user = this.systemUsers.find(user => user._id == n.userId);
                    if (user) authorName = user.name;
                }
                return Object.assign({ authorName }, n);
            }).sort(function(a, b) {
                const dateA = new Date(a.created), dateB = new Date(b.created);
                return dateB - dateA;
            });
        }
    },
    created: function() {
        this.userId = UserManager.user.userId;

        SystemUsersManager.getSystemUsers().then((systemUsers) => {
            this.systemUsers = systemUsers;
        });
    },
    methods: {
        async openNotesModal() {
            const note = await this.$modal2.show(GenericModal, {
                title: "Dodaj nową notatkę",
                schema: {
                    text: { label: "Treść notatki", type: 'textarea' },
                    addPrintTag: { label: "Umieść notatkę na wydruku", type: 'checkbox' },
                    isProductionNote: { label: "Przekaż notatkę na produkcję", type: 'checkbox' }
                }
            });

            if (!note) return;
            if (note.addPrintTag) note.text = "[[Druk-etykieta]]\n" + note.text;

            this.procesing = true;
            try {
                const notes = await Api.order.addNote(this.orderId, { content: note.text, isProductionNote: note.isProductionNote });
                this.notes.splice(0);
                this.notes.push(...notes);
                this.$h.saveNotif();
            } catch (error) {
                this.$h.commonApiError(error);
            }
            this.procesing = false;
        },

        remove: async function(noteId) {
            const c = await this.$h.confirm("Czy na pewno usunąć tą notatkę?");
            if (!c) return;

            this.procesing = true;
            try {
                const notes = await Api.order.removeNote(this.orderId, noteId);
                this.notes.splice(0);
                this.notes.push(...notes);
                this.$h.removeNotif();
            } catch (error) {
                this.$h.commonApiError(error);
            }
            this.procesing = false;
        },
        autoGrow: function() {
            const element = document.getElementById('comment-mesage-area');
            element.style.height = "5px";
            element.style.height = (element.scrollHeight) + "px";
        }
    }
}
</script>
<style scoped lang="scss">
.comment-box {
  position: relative;
  .comment-box-input {
    background: #FFFFFF;
    box-shadow: none;
    height: 73px;
    min-height: 73px;
    width: 100%;
    resize: none;
  }
}
.content-comment {
//   border: 1px solid rgba(158, 158, 158, 0.1);
  border: 1px solid rgb(225, 192, 14);
  position: relative;
  .essence {
    b {
      color: #0cc2aa;
    }
  }
}
.md-16 {
  font-size: 16px;
}
</style>
