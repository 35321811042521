<template>
    <div class="box m-b-0 box-modal">
        <div class="box-header">
            <h3>Dodaj przesyłkę do zamówienia</h3>
            <small>Umożliwia ręczne dodanie przesyłki utworzonej poza systemem</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <form role="form">
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Przewoźnik</label>
                    <div class="col-sm-9">
                        <v-select placeholder="Nie wybrano" label="name" v-model="ship.service" :options="delivery"  />
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Ilość paczek</label>
                    <div class="col-sm-3">
                        <input class="form-control custom-input" type="number" v-model="ship.boxesQty">
                    </div>
                </div>
                <div class="form-group row m-b-xs m-t">
                    <label class="col-sm-3 form-control-label">Wybrane kartony/palety</label>
                    <div class="col-sm-9">
                        <span class="m-r-sm m-b-xs label blue text-sm" v-for="(b, idx) in selectedBoxAndPallets" :key="b.id">
                            <span v-if="b.code == 'Paleta'">{{b.code}} {{idx+1}}</span>
                            <span v-else>Karton: #{{b.code}}</span>
                            <a class="text-sm" @click.prevent="removeBox(b)">
                                <i class="material-icons md-18">close</i>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="form-group row m-b-xs" v-if="ship.service && (ship.service.id =='dhl' || ship.service.id =='ups' || ship.service.id =='inpost')">
                    <span class="form-file">
                        <input type="file" ref="files" multiple @change="onAdd" style="display: none;">
                        <a href="#" class="pull-right text-blue text-sm m-t m-r" @click.prevent="$refs.files.click();">Dodaj etykietę</a>
                    </span>
                </div>
                <div class="form-group row">
                    <div class="pdf-box m-b-sm" v-for="uf in uploadedFiles"  v-if="!uf.isSent">
                        <div class="p-y-sm p-x">
                            <div class="pull-left m-r">
                                <i class="material-icons">blur_linear</i>
                            </div>
                            <div class="clear">
                                <div class="text-muted">{{uf.file.name | truncate(25) }}</div>
                                <div class="progress m-t-xs" v-if="!isError && uf.progress < 100">
                                    <div class="progress-bar blue" :style="{ width: uf.progress + '%' }">{{uf.progress}} %</div>
                                </div>
                                <div  class="upload-error p-a-xs m-t-xs" v-if="isError">
                                    <span class="text-white text-xs">Wystąpił błąd w trakcie wysyłania pliku. Spróbuj przesłać plik jeszcze raz lub zgłoś problem do swojego admina.</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="form-group row" v-if="attachments.length > 0 ">
                    <div class="pdf-box m-b-sm" v-for="a in attachments">
                        <div class="p-y-sm p-x-sm">
                            <div class="pull-left m-r">
                                <i class="material-icons">blur_linear</i>
                            </div>
                            <div class="clear">
                                <div>
                                    <a class="text-blue" :href="Api.getFileUrl(a.key, a.name)" target="_blank">{{a.name | truncate(25) }}</a>
                                    <a class="text-muted pull-right text-red" href="#" @click.prevent="remove(a)">
                                        <i class="material-icons md-18"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>
                <div v-if="isLoadingReadShipmentLabel" class="row">
                    <div class="col-md-2"> <rotate-square2 style="margin: 15px;"/></div>
                    <div class="col-md-8 m-t"> <span class="text-xs text-muted">Skanowanie etykiety...</span></div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Numer przesyłki</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="ship.parcelNo">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Adres odbioru</label>
                </div>
                <hr>

                <div class="row m-x">
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Nazwa</label>
                        <div class="col-sm-9">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.name">
                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Ulica</label>
                        <div class="col-sm-9">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.street">
                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Miasto</label>
                        <div class="col-sm-3">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.postCode" placeholder="XX-XXX">
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.city">
                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Kraj</label>
                        <div class="col-sm-9 size-select">
                            <v-select placeholder="Nie wybrano" label="text" value="id" v-model="ship.receiverAddress.countryCode" :reduce="c => c.id" :options="cauntries"  />

                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Telefon</label>
                        <div class="col-sm-9">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.phone">
                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Email</label>
                        <div class="col-sm-9">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.email">
                        </div>
                    </div>
                    <div class="form-group row m-b-xs">
                        <label class="col-sm-3 form-control-label">Osoba do kontaktu</label>
                        <div class="col-sm-9">
                            <input class="form-control custom-input" type="text" v-model="ship.receiverAddress.contactPerson">
                        </div>
                    </div>
                </div>

            </form>
        </div>
        <div class="box-footer clearfix" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer clearfix" v-else>
            <button class="btn white none-radius btn-sm" @click="$emit('close')">Zamknij</button>
            <button class="pull-right btn info none-radius btn-sm" type="button" style="height: 32px;" @click="save">Zapisz</button>

        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import countryCodes from "../../../Common.js";
import EventBus from '../../../event-bus.js';

export default {
    name: 'AddShipmentModal',
    props: {
        order: Object,
        selected: Array
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            isProcessing: false,
            ship: {
                service: null,
                boxesQty: 1,
                order: this.order._id,
                parcelNo: null,
                status: 'sent',
                receiverAddress: {
                    name: "",
                    street: "",
                    postCode: "",
                    city: "",
                    countryCode: "PL",
                    phone: "",
                    email: "",
                    contactPerson: ""
                }
            },
            cauntries: countryCodes,
            delivery: [
                { id: 'dpd', name: 'dpd' },
                { id: 'furgonetka', name: 'furgonetka' },
                { id: 'fedex', name: 'fedex' },
                { id: 'rhenus', name: 'rhenus' },
                { id: 'geis', name: 'geis' },
                { id: 'suus', name: 'suus' },
                { id: 'pp', name: 'poczta polska' },
                { id: 'dhl', name: 'dhl' },
                { id: 'ups', name: 'ups' },
                { id: 'inpost', name: 'inpost' },
                { id: 'other', name: 'inny' }
            ],
            uploadedFiles: [],
            isError: false,
            attachments: [],
            Api: Api,
            isLoadingReadShipmentLabel: false,
            selectedBoxAndPallets: []
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    created: function() {
        if (this.order.deliveryAddressId) {
            const address = this.order.customer.deliveryAddresses;
            const idx = address.map(d => d._id + "").indexOf(this.order.deliveryAddressId + "");
            if (idx != -1) {
                Object.assign(this.ship.receiverAddress, address[idx]);
            }
        }

        // jeśli są wybrane kartony lub palety to musimy zredukować możlwiości servicu dla palet jak i kartonów ustawic boxQty i wyświetlić liste kodów kartonów
        // {id: id potrzebne do zapisu, kod:potrzebny do wyświetlenia}
        if (this.selected) {
            this.ship.boxesQty = 0;
            let selectedPaletts = [];
            if (this.order.shippingPalletes) {
                const idsPalette = this.order.shippingPalletes.map(p => p._id);
                selectedPaletts = this.selected.filter(value => idsPalette.includes(value));
                // jeśli selected zawierają id palety to serwis musi byc tylko geisa i rhenusa
                // if (selectedPaletts.length > 0) {
                //     this.delivery = this.delivery.filter(d => d.id == 'geis' || d.id == ' rhenus');
                //     console.log("są wybrane palety", this.delivery)
                // } else {
                //     this.delivery = this.delivery.filter(d => d.id != 'geis' || d.id != ' rhenus');
                //     console.log("brak palet", this.delivery)
                // }

                for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                    const palette = this.order.shippingPalletes[i];
                    const idx = this.selected.indexOf(palette._id);
                    if (idx != -1) {
                        const obj = { id: palette._id, code: "Paleta" };
                        this.selectedBoxAndPallets.push(obj);
                        this.ship.boxesQty += 1;
                    }
                }
            }

            if (this.order.boxes) {
                for (let i = 0; i < this.order.boxes.length; i++) {
                    const box = this.order.boxes[i];
                    const idx = this.selected.indexOf(box._id);
                    if (idx != -1) {
                        const obj = { id: box._id, code: box.code };
                        this.selectedBoxAndPallets.push(obj);
                        if (selectedPaletts.length == 0) this.ship.boxesQty += 1;
                    }
                }
            }
        }
    },
    methods: {
        save: async function() {
            if (!this.ship.service || !this.ship.parcelNo || this.ship.parcelNo.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Numer przesyłki i przewoźnik nie mogą byc puste!' });
                return;
            }
            if (this.attachments.length > 0) this.ship.attachments = this.attachments;
            this.isProcessing = true;
            try {
                const qty = parseFloat(this.ship.boxesQty);
                this.ship.boxesQty = qty;
                this.ship.service = this.ship.service.id;
                const s = await Api.shipments.add(this.ship);
                const o = await Api.order.setStatus(this.order._id, "sent");

                await this.setShipmentToPalettsAndBoxes(s);

                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zmieniono.' });
                this.$set(this.order, "status", o.status);
                this.selected.length = 0;
                EventBus.$emit('orderShipmentCreated', s);
                this.$emit('close');
            } catch (error) {
                console.log("errrt", error)
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas tworzenia przesyłki.' });
            }
            this.isProcessing = false;
        },
        async setShipmentToPalettsAndBoxes(s) {
            try {
                for (let i = 0; i < this.selectedBoxAndPallets.length; i++) {
                    const selected = this.selectedBoxAndPallets[i];
                    if (selected.code == 'Paleta') await Api.order.editPalette(this.order._id, selected.id, { shipmentId: s._id });
                    else await Api.order.editBox(this.order._id, selected.code, { shipmentId: s._id });
                }
            } catch (error) {
                console.log("err", error)
            }
        },
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        },
        onAdd: function() {
            const uploadedFiles = this.$refs.files.files;
            this.handleFilesUpload(uploadedFiles);
        },
        handleFilesUpload: function(uploadedFiles) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                const fObj = {
                    file: uploadedFiles[i],
                    progress: 0,
                    isSent: false
                };

                this.uploadedFiles.push(fObj);

                const formData = new FormData();
                formData.append('file', uploadedFiles[i]);

                this.isError = false;
                this.isEmpty = false;

                Api.uploadFile(formData, (progressEvent) => {
                    fObj.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                })
                    .then((ob) => {
                        fObj.isSent = true;
                        this.attachments.push({ key: ob.fileKey, name: ob.fileName });
                        this.readShipmentLabel(ob.fileKey, ob.fileName)
                    }).catch((reson) => {
                        this.isError = true;
                    });
            }
        },
        async readShipmentLabel(key, name) {
            this.isLoadingReadShipmentLabel = true;
            try {
                const data = await Api.shipments.readShipmentLabel({ service: this.ship.service.id, fileKey: key, fileName: name });
                if (data.shipmentNo) this.ship.parcelNo = data.shipmentNo;
            } catch (error) {
                this.$helpers.errorNotif('Wystąpił błąd.');
                console.log("err", error)
            }
            this.isLoadingReadShipmentLabel = false;
        },
        remove: function(attach) {
            this.attachments = this.attachments.filter(a => a.key != attach.key);
        },
        removeBox(b) {
            this.selectedBoxAndPallets = this.selectedBoxAndPallets.filter(s => s.id != b.id);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
