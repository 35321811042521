<template>
    <div class="row">
        <div class="col-md-2">
            <div class="box">
                <div class="box-body p-b-sm">
                    <p class="primary-color _500 m-b-0">Dziś nadane przesyłki</p>
                </div>
                <div class="m-a-0 custom-divider"/>
                <table class="table m-b-0">
                    <thead class="primary-color d-gray">
                        <tr>
                            <th class="text-sm">Przewoźnik</th>
                            <th class="text-sm">Przesyłki</th>
                            <th class="text-sm"/>
                            <!-- <th><small class="text-muted">(kartony/palety)</small></th> -->
                        </tr>
                    </thead>

                    <tbody class="primary-color" v-if="!isLoading" >
                        <tr v-for="(r, key) in shipmentsRaports" :key=key>
                            <td>
                                <span v-if="r.service == 'pp'">poczta polska</span>
                                <span v-else>{{r.service}}</span>
                            </td>
                            <td style="padding-right:0px;">{{r.qty}}</td>
                            <td style="padding-right:0px; padding-left:0px;">
                                <span class="text-muted pull-left" v-if="r.service == 'geis' || r.service == 'suus'">(palet:  {{r.boxes}})</span>
                                <span class="text-muted pull-left" v-else-if="r.service == 'rhenus'">(palet/kartonów:  {{r.boxes}})</span>
                                <span class="text-muted pull-left" v-else>(kartonów:  {{r.boxes}})</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Spinner v-if="isLoading" />
            </div>
            <ShipmentHandedOverToCurier/>
            <Filters v-model="filters" @change="getShipments" />
            <GeisPickUp/>
            <PPSendEnvelope/>
        </div>
        <div class="col-md-10">
            <SortList v-model="filters" @change="getShipments" />
            <div class="box">
                <div class="box-header d-gray">
                    <h6 class="text-muted _400 inline"><span>Lista przesyłek</span>&nbsp;</h6>
                    <div class="pull-right minus-margin m-t-xs">
                        <a class="m-r-sm  text-blue" @click.prevent="showModal()">
                            <i class="material-icons md-20">add</i>
                            Eksport CSV
                        </a>&nbsp;
                    </div>
                </div>
                <BtnGroup :shipments="selectedShipments" @changed="onShipmentsChanged" />
                <div style="position: relative;" v-if="isLoading">
                    <Spinner/>
                </div>
                <div style="position: relative;" v-else>
                    <DelvieryTable
                        :shipments="shipments" v-if="!isLoading"
                    />
                    <footer class="dker p-a" v-if="shouldRender">
                        <div class="row">
                            <div class=" col-sm-12 text-right text-center-xs">
                                <paginate
                                    v-model="page"
                                    :page-count="pages"
                                    :click-handler="changePage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'pagination pagination-sm m-a-0'"/>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

let lastFilters = {
    service: [],
    status: [],
    query: "",
    dateFrom: "",
    dateTo: "",
    offset: 0
};

import OptionForEksportShipCSVModal from './modals/OptionForEksportShipCSVModal.vue'
import Filters from './Filters.vue'
import ShipmentHandedOverToCurier from './ShipmentHandedOverToCurier.vue'
import SortList from './SortList.vue'
import BtnGroup from './BtnGroup.vue'
import DelvieryTable from './DelvieryTable.vue'
import GeisPickUp from './GeisPickUp.vue'
import PPSendEnvelope from './PPSendEnvelope.vue'
import Api from "../../Api.js";
import Spinner from '../Spinner.vue';

const POS_PER_PAGE = 50;

export default {
    name: 'Semi',
    components: {
        Filters,
        SortList,
        BtnGroup,
        Spinner,
        DelvieryTable,
        GeisPickUp,
        PPSendEnvelope,
        ShipmentHandedOverToCurier
    },
    data: function () {
        return {
            isLoading: false,
            shipments: [],
            filters: {},
            shouldRender: true,
            shipmentsRaports: [
                { service: "dpd", qty: 0, boxes: 0 },
                { service: "fedex", qty: 0, boxes: 0 },
                { service: "furgonetka", qty: 0, boxes: 0 },
                { service: "rhenus", qty: 0, boxes: 0 },
                { service: "geis", qty: 0, boxes: 0 },
                { service: "pp", qty: 0, boxes: 0 },
                { service: "marss", qty: 0, boxes: 0 },
                { service: "suus", qty: 0, boxes: 0 }
            ],
            pages: 0,
            page: 1,
            offset: 0,
            totalFound: null
        }
    },

    computed: {
        selectedShipments: function() {
            return this.shipments.filter(sh => sh.isSelected);
        }
    },

    created: function() {
        this.filters = lastFilters;
        this.getShipments();
        this.getTodayShipments();
    },

    destroyed: function() {
        lastFilters = JSON.parse(JSON.stringify(this.filters));
    },

    methods: {
        getTodayShipments: async function() {
            let today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;

            this.isLoading = true;
            const filters = {
                dateFrom: today + "T00:00:01",
                dateTo: today + "T23:59:59",
                limit: 200
            }

            try {
                const tmpShip = await Api.shipments.search(filters);
                for (let i = 0; i < tmpShip.length; i++) {
                    const ship = tmpShip[i];
                    if (!ship.order) continue;
                    const idx = this.shipmentsRaports.map(s => s.service).indexOf(ship.service);
                    if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                    if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                }
            } catch (error) {
                this.isError = true;
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się załadować raportu paczek' });
            }
            this.isLoading = false;
        },
        getShipments: async function() {
            this.isLoading = true;
            try {
                const data = await Api.shipments.searchWithTotal(this.filters);
                const tmpShip = data.data;
                const total = data.total;

                this.totalFound = data.total;
                this.pages = Math.ceil(data.total / POS_PER_PAGE);

                for (let i = 0; i < tmpShip.length; i++) {
                    const shipment = tmpShip[i];
                    shipment.isSelected = false;
                }
                this.shipments = tmpShip;
            } catch (error) {
                this.isError = true;
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się załadować listy przesyłek' });
            }
            this.isLoading = false;
        },

        onShipmentsChanged: function(shipments) {
            this.getShipments();
        },
        changePage: function(pageNum) {
            this.filters.offset = (pageNum * 50) - 50;
            this.getShipments();
        },
        showModal() {
            this.$modal.show(OptionForEksportShipCSVModal, {
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                width: '660px'
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    tr.collapse.in {
        display:table-row;
        max-height: 500px;
    }
    tr.collapse {
        max-height: 0;
        transition: height 0.25s ease-in;
    }
    .flex {
        display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      height: 40px;
    }
</style>
