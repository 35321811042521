<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Adres dostawy</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Nazwa</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.name">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Ulica</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.street">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Miasto</label>
                    <div class="col-sm-3">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.postCode" placeholder="XX-XXX">
                    </div>
                    <div class="col-sm-6">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.city">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Kraj</label>
                    <div class="col-sm-9 size-select">
                        <select2 :options="cauntries" v-model="deliveryAddress.countryCode" class="form-control"/>
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Telefon</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.phone">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Email</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.email">
                    </div>
                </div>
                <div class="form-group row m-b-xs">
                    <label class="col-sm-3 form-control-label">Osoba do kontaktu</label>
                    <div class="col-sm-9">
                        <input class="form-control custom-input" type="text" v-model="deliveryAddress.contactPerson">
                    </div>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer" v-else/>
    </div>
</template>

<script>
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import countryCodes from "../../Common.js";

export default {
    name: 'EditDeliveryCustomerModal',
    props: {
        delivery: Object
    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            deliveryAddress: {
                countryCode: "PL"
            },
            Api,
            isProcessing: false,
            cauntries: countryCodes
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (this.delivery) {
            this.deliveryAddress = JSON.parse(JSON.stringify(this.delivery)); // deep-copy
        }
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        saveAndExit: async function () {
            Object.assign(this.delivery, this.deliveryAddress);
            this.$h.saveNotif();
            this.$emit('close');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
