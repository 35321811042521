<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm inline">
                Dane klienta
                <a class="pull-right" title="Dodaj powiązanie" @click.prevent="edit">
                    <i class="material-icons md-18 text-blue">edit</i>
                </a>
            </h2>
        </div>
        <div class="m-a-0 custom-divider"/>

        <ul class="list-group no-border m-t-sm">
            <li class="list-group-item no-border" v-if="!isChange">
                <div class="clear custom-color">
                    <h6 class="_400 m-b" v-if="customer.name">{{customer.name}}</h6>
                    <h6 class="_400 m-b" v-else>Brak nazwy</h6>

                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Email: </span>
                        <span class="col-md-8 col-sm-6" v-if="customer.email">{{customer.email}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Ulica: </span>
                        <span class="col-md-8 col-sm-6" v-if="customer.street">{{customer.street}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Miasto: </span>
                        <span class="col-md-8 col-sm-6" v-if="customer.postCode && customer.city">
                            {{customer.postCode}} {{customer.city}}
                        </span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Telefon: </span>
                        <span class="col-md-8 col-sm-6" v-if="customer.phone">{{customer.phone}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Numer {{customer.taxNoType}}: </span>
                        <span class="col-md-8 col-sm-6" v-if="customer.taxNo">
                            {{customer.taxNo}}
                        </span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                </div>
                <div class="row m-b-xs text-right m-t-sm">
                    <div class="col-md-12">
                        <a href="#" class="text-blue text-sm" @click.prevent="addDeliveryAddress">Utwórz adres dostawy</a>
                    </div>
                </div>
                <hr>
                <div class="comment-box">
                    <textarea class="form-control form-control-sm comment-box-input" placeholder="Dowolne dodatkowe informacje o tym kliecie" v-model="cNote" @input="onNotesChange" />
                </div>
            </li>
        </ul>

    </div>
</template>
<script>
import Api from '../../Api.js';
import EditCustomerModal from './EditCustomerModal.vue';
import EditDelvieryCustomerModal from './EditDelvieryCustomerModal.vue';
let timeoutHandler = null;

export default {
    name: 'Detalis',
    props: {
        customerData: Object
    },
    components: {
    },
    data: function () {
        return {
            customer: JSON.parse(JSON.stringify(this.customerData)),// deep-copy 
            isChange: false,
            isEmpty: false,
            isLoaded: false,
            cNote: "null"
        }
    },
    watch: {
        customerData(val) {
            this.customer = JSON.parse(JSON.stringify(val));// deep-copy
        }
    },
    created: function() {
        this.cNote = this.customer.notes || "";
    },
    methods: {
        edit: function() {
            const customer = this.customer;
            this.$modal.show(EditCustomerModal, {
                customer
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        },
        addDeliveryAddress: async function() {
            let adress = {
                name: this.customer.name,
                street: this.customer.street,
                postCode: this.customer.postCode,
                city: this.customer.city,
                countryCode: this.customer.countryCode,
                phone: this.customer.phone,
            };
            let res = await this.$modal2.show(EditDelvieryCustomerModal, {
                customer: this.customer,
                delivery: adress,
            });

            if(res) this.$emit('deliveryUpdate', res);
        },
        onNotesChange: function() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(async () => {
                try {
                    await Api.customer.edit(this.customer._id, { notes: this.cNote });
                    this.$h.saveNotif();
                } catch (error) {
                    this.$h.commonApiError(error);
                }
            }, 1000);
        }

    }
}
</script>
