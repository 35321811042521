import Api from './Api.js';
import EventEmitter from 'events';
import SystemSocket from './SystemSocket.js';

const ding = require("./assets/sounds/sound3.wav");

class UserManager {
    constructor() {
        this.user = null;
        this.permissions = [];
        this.token = localStorage.getItem('token');
        this.events = new EventEmitter();
        this.notifications = [];
        this.bc = new BroadcastChannel('user_manager_channel');

        if (this.token) {
            this.verifyUser(this.token);
        }

        this.bc.onmessage = (msg) => {
            if (!msg.data || msg.data.ev != 'user_ready') return;
            this.user = msg.data.user;
            this.token = msg.data.token;
            this.notifications = msg.data.notifications;
            this.events.emit('loggedIn', this.token);
            this.events.emit('userReady', this.user);
        };

        window.addEventListener("storage", (event) => {
            if (event.key != "token") return;
            if (!event.newValue) {
                this.token = null;
                this.events.emit('loggedOut');
            } else {
                this.token = event.newValue;
            }
        })

        SystemSocket.socket.on('object', (msg) => {
            if (msg.object == 'notification') {
                if (msg.verb == 'create') {
                    const audio = new Audio(ding);
                    audio.play();
                    this.notifications.push(msg.data);
                    this.events.emit('notification_changed', this.notifications);
                } else if (['update', 'delete', 'save'].indexOf(msg.verb) != -1) {
                    const idx = this.notifications.map(n => n._id + "").indexOf(msg.data._id + "");
                    if (idx != -1) this.notifications.splice(idx, 1);
                    if (['update', 'save'].indexOf(msg.verb) != -1) this.notifications.push(msg.data);
                    this.events.emit('notification_changed', this.notifications);
                }
            }
        });
    }

    logIn(email, pass) {
        return Api.user.logIn(email, pass).then((data) => {
            this.token = data.token;
            Api.setToken(this.token);
            SystemSocket.setToken(this.token);
            localStorage.setItem('token', this.token);
            this.events.emit('loggedIn', this.token);
            this.verifyUser(this.token);
            return this.token;
        });
    }

    isLogged() {
        if (this.token != null) return true;
    }

    async verifyUser(token) {
        try {
            const res = await Api.user.verifyUser(token);
            if (!res.valid) {
                this.logOut();
                return;
            }

            this.user = res.credentials;
            Api.setToken(token);
            SystemSocket.setToken(token);

            const user = await Api.user.getOne(this.user.userId);
            this.permissions = user.permissions || [];

            const notifications = await Api.user.getNotifications();
            this.notifications = notifications;
            this.bc.postMessage({
                ev: 'user_ready',
                user: this.user,
                token: this.token,
                notifications: this.notifications
            });
            this.events.emit('userReady', this.user);
        } catch (error) {
            this.events.emit('verifyError', error);
        }
    }

    hasPermission(permissionCode) {
        const uPerms = this.permissions || [];
        if (uPerms.includes('*')) return true;
        return uPerms.includes(permissionCode);
    }

    async logOut() {
        this.token = null;
        this.user = null;
        localStorage.removeItem('token');
        this.events.emit('loggedOut');
        await Api.user.logOut();
    }
}

const instance = new UserManager();

export default instance;
