<template>
    <div class="box">
        <div class="box-header" :class="headerClass">
            <h6 class="text-muted _400 inline">{{ title }}</h6>
        </div>
        <div class="box-tool" v-if="hasESCButton">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('esc')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <slot/>
        </div>
        <div class="box-footer clearfix">
            <slot name="footer"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'BoxComponent',
    props: {
        title: {
            type: String,
            required: false
        },
        headerClass: {
            type: String,
            required: false,
            default: 'd-gray'
        },

        /**
         * If true, the component will listen for ESC key and emit 'esc' event
         */
        hasESCButton: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            isEditMode: false,
            isNumber: false,
            val: null,
            tmp: null
        }
    },
    components: {

    },
    mounted() {
        if (this.hasESCButton) {
            window.addEventListener('keyup', this.handleKeyUp);
        }
    },

    unmounted() {
        if (this.hasESCButton) {
            window.removeEventListener('keydown', this.handleKeyUp);
        }
    },

    computed: {

    },
    methods: {
        handleKeyUp(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('esc');
            }
        }
    }
}
</script>

<style scoped>
</style>
