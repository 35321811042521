<template>
    <form class="form-horizontal m-b">
        <div v-for="(obj, key) in schema" :key="key">
            <div class="form-group row" v-if="!obj.dependsOn || (inputs[obj.dependsOn])">
                <label class="col-sm-4 form-control-label">{{obj.name}}</label>
                <div v-if="obj.type=='select_single'" class="col-sm-8">
                    <v-select  :placeholder="obj.name" style="width:100%;" v-model="inputs[key]"  :options="obj.values" label="label" :reduce="t => t.id" @input="submitChanges"/>
                    <small v-if="obj.description" class="text-muted">{{obj.description}}</small>
                </div>
                <div v-else-if="obj.type=='select_multiple'" class="col-sm-8">
                    <v-select multiple="multiple" :taggable="!!obj.taggable"  :placeholder="obj.name" style="width:100%;" v-model="inputs[key]"  :options="obj.values" label="label" :reduce="t => t.id" @input="submitChanges"/>
                    <small v-if="obj.description" class="text-muted">{{obj.description}}</small>
                </div>
                <div v-else-if="obj.type=='date'" class="col-sm-8">
                    <datepicker
                        :monday-first="true"
                        :bootstrap-styling="true"
                        :typeable="true"
                        @input="submitChanges"
                        clear-button
                        format="yyyy-MM-dd"
                        placeholder="-"
                        v-model="inputs[key]"
                        input-class="form-control custom-input" />
                </div>
                <div v-else-if="obj.type=='checkbox'" class="col-sm-4 m-l-1">
                    <div class="md-check">
                        <input type="checkbox" @change="submitChanges" v-model="inputs[key]">
                        <i class="blue m-r-xs"/>
                    </div>
                    <small v-if="obj.description" class="text-muted">{{obj.description}}</small>
                </div>
                <div v-else-if="obj.type=='number'" class="col-sm-8">
                    <input class="form-control" type="number" v-model.number="inputs[key]" @input="submitChanges">
                    <label v-if="!obj.required"><span class="text-muted text-sm">(opcjonalnie)</span></label>
                </div>
                <div v-else-if="obj.type=='text' || obj.type == 'password'" class="col-sm-8">
                    <input class="form-control" :type="obj.type" v-model="inputs[key]" @input="submitChanges">
                    <p class="text-muted text-sm" v-if="obj.description">{{obj.description}}</p>
                    <label v-if="!obj.required"><span class="text-muted text-sm">(opcjonalnie)</span></label>
                </div>
                
            </div>
        </div>
    </form>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'SchemaForm',
    props: {
        value: {
            type: Object,
            required: true
        },
        schema: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            inputs: {}
        }
    },
    components: {
        Datepicker
    },
    created: function() {
        for (const key in this.schema) {
            this.$set(this.inputs, key, this.value[key]);
        }
    },
    methods: {
        submitChanges() {
            const c = {};
            for (const key in this.schema) {
                c[key] = this.inputs[key]
            }
            this.$emit('input', c);
        }
    }
}
</script>

<style scoped lang="scss">
  .overlay {
    position: relative;
    .box {
      height: 100%;
      width: 94%;
      opacity: 0.5;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  .work-in-progress {
    position: absolute;
    left: 27%;
    top: 130px;
    border: 2px solid rgba(196,198,198, 0.6);
    z-index: 99999;
    padding: 12px 10px;
    span {
      color: #fff;
      background-color: rgba(196,198,198, 0.6);
      padding: 10px 8px;
    }
  }
  .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
    }
</style>
