<template>
    <div class="box-body">
        <div class="row">
            <div class="box">

                <div class="box-header d-gray">
                    <h2 class="text-muted _400 inline">
                        <span v-if="isChanged && !isSaving">(*) </span>
                        <span v-if="isSaving">(Zapisywanie) </span>
                        <span v-if="template.name">{{ template.name }}</span>
                        <span v-else>Brak nazwy</span>
                    </h2>
                    <div class="pull-right">
                        <div class="btn-group primary-color order-btn-group">
                            <a class="nav-link btn btn-xs white none-radius" title="Zapisz" @click.prevent="save()" :class="{'diabled': isSaving}">
                                <i class="material-icons md-18">done</i>
                            </a>

                            <a class="nav-link btn btn-xs white none-radius" title="Anuluj zmiany" @click="back">
                                <i class="material-icons md-18">highlight_off</i>
                            </a>
                            <a class="nav-link btn btn-xs white none-radius" title="Usuń" @click.prevent="confirmRemove" :class="{'diabled': isLoading}">
                                <i class="material-icons md-18">delete</i>
                            </a>
                            <a class="nav-link btn btn-xs white none-radius" title="Wróć do listy" @click="back">
                                <i class="material-icons md-18">arrow_back</i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="box-divider m-a-0"/>

                <div class="box-body" style="min-height:800px; padding-left: 0px; padding-top: 0px;">
                    <div class="col-md-6 m-t">
                        <form class="">
                            <div class="form-group">
                                <label class="form-control-label">Nazwa</label>
                                <input class="form-control" type="text" v-model="template.name">
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Nazwa do wyświetlenia</label>
                                <input class="form-control" type="text" v-model="template.iconURL">
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Format</label>
                                <select class="form-control" v-model="template.format">
                                    <option value="pdf">PDF</option>
                                    <option value="zpl">ZPL</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="form-control-label">Wymiar etykiety</label>
                                <!-- <select class="form-control" v-model="template.size">
                            <option v-for="format in formats" v-bind:value="format">{{ format }}</option>
                        </select> -->
                                <v-select class="form-control" label="name" v-model="template.size" :filterable="false" :taggable="true" :options="formats"/>
                            </div>

                            <div class="form-group" v-if="template.format == 'pdf'">
                                <label class="form-control-label">Orientacja</label>
                                <select class="form-control" v-model="template.layout">
                                    <option value="portrait">Pionowo</option>
                                    <option value="landscape">Poziomo</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="form-control-label">Format danych</label>
                                <select class="form-control" v-model="template.dataScheme">
                                    <option v-for="(desc, key) in dataSchemas" :value="key" :key="key">{{ desc }}</option>
                                </select>
                                <pre v-if="template.dataScheme == 'pallet'">// Przykładowe dane {{ samplePalletData }}</pre>
                                <pre v-if="template.dataScheme == 'productQualityCard'">// Przykładowe dane {{ sampleProductCardData }}</pre>
                            </div>

                            <div class="form-group" v-if="template.dataScheme == 'custom'">
                                <label class="form-control-label">Argumenty</label>
                                <div class="form-group row" v-for="(arg, i) in template.arguments">
                                    <div class="col-md-4">
                                        <input type="text" class="form-control" placeholder="klucz" v-model="arg.key">
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control" placeholder="Nazwa" v-model="arg.name">
                                    </div>
                                    <div class="col-md-3">
                                        <select class="form-control" v-model="arg.type">
                                            <option v-for="t in argTypes" :value="t">{{ t }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-1">
                                        <a @click.prevent="removeTplArg(i)" class="nav-link btn btn-xs white none-radius" title="Usuń argument" style="height: 38px;line-height: 36px;">
                                            <i class="material-icons md-18">highlight_off</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <a href="#" @click.prevent="template.arguments.push({type: 'object'})"><i class="material-icons md-18">add</i> Dodaj argument</a>
                                </div>
                            </div>

                            <label class="form-control-label">Media</label>
                            <div class="form-group row" v-for="(medium, i) in template.media">
                                <div class="col-md-4">
                                    <input :disabled="isUploadingMedia" type="text" class="form-control" placeholder="klucz" v-model="medium.key">
                                </div>
                                <div class="col-md-6">
                                    <input :disabled="isUploadingMedia" type="text" class="form-control" placeholder="Adres" v-model="medium.url">
                                </div>
                                <div class="col-md-2">
                                    <a :href="medium.url" target="_blank" class="nav-link btn btn-xs white none-radius" title="Podgląd" style="height: 38px;line-height: 36px;">
                                        <i class="material-icons md-18">zoom_in</i>
                                    </a>

                                    <a @click.prevent="currentlyUploadedMedium = medium; $refs.medium_file.click()" class="nav-link btn btn-xs white none-radius" title="Wgraj plik" style="height: 38px;line-height: 36px;">
                                        <i class="material-icons md-18">cloud_upload</i>
                                    </a>

                                    <a @click.prevent="template.media.splice(i, 1);" class="nav-link btn btn-xs white none-radius" title="Usuń argument" style="height: 38px;line-height: 36px;">
                                        <i class="material-icons md-18">highlight_off</i>
                                    </a>
                                </div>
                            </div>
                            <div class="form-group row">
                                <a href="#" @click.prevent="template.media.push({url: 'http://'})"><i class="material-icons md-18">add</i> Dodaj nowe medium</a>
                            </div>

                            <input type="file" style="display:none;" ref="medium_file" @change="handleMediumFile" accept="image/*" >
                        </form>
                    </div>
                    <div class="col-md-6" style="padding-left: 0px; border-right: 1px solid rgba(120, 130, 140, 0.13);">
                        <textarea name="program_code"/>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style>
  .form-control .vs__search::placeholder,
  .form-control .vs__dropdown-toggle,
  .form-control .vs__dropdown-menu {
    /* border-color: rgba(120, 130, 140, 0.2); */
    border: none;
    border-radius: 0;
    /* color: #394066; */
    /* text-transform: lowercase; */
    /* font-variant: small-caps; */
  }

  .form-control .vs__clear,
  .form-control .vs__open-indicator {
    fill: #394066;
  }
</style>

<style lang="scss" scoped>
    @import '../../assets/styles/codemirror.css';
    @import '../../assets/styles/monokai.css';
    .helper {
        border: 1px solid #5bc0de;
        background-color: rgba(91,192,222, 0.2);
    }
    .box-error {
        border: 1px solid #f05050;
        background-color: rgba(240, 80, 80, 0.2);
    }
</style>

<style lang="scss">
    .line-error {
        background-color: #ee453f;
    }
    .line-warning {
        background-color: #ff45ff;
    }

    .graph-visualize {
        width: 100%;
        height: 400px;
        border: 1px solid lightgray;
    }
</style>

<script>
import Api from '../../Api.js';
import _CodeMirror from '../../assets/libjs/codemirror.js'
import '../../assets/libjs/codemirror.javascript.js'
const CodeMirror = window.CodeMirror || _CodeMirror

let editor;
let graph;

const dummyCode = "// Globalnie dostepna jest zmienna \"doc\"" +
                    "\ndoc.rect(10, 10, 210, 210).stroke(); // narysuje prostokat" +
                    "\ndoc.text('Some text', 15, 15); // osadzi tekst na pozycji x:15, y:15";

/**
     * Zwraca numer lini w edytorze gdzie wystepuje obiekt
     * @param {string} key klucz np. "elements.ramka"
     * @returns {number} numer lini w edytorze
     */
function getObjectLine(key) {
    const yamlCode = editor.getValue();
    const parts = key.split('.');
    const lines = yamlCode.split("\n");
    let searchingPartIdx = 0;
    let foundLine = 0;
    for (let i = 0; i < lines.length; i++) {
        const l = lines[i].replace(/#.*$/, '').trim().split(':')[0]; // wywalamiy komentarze
        if (l == parts[searchingPartIdx]) {
            searchingPartIdx += 1;
            foundLine = i;
        }

        if (searchingPartIdx >= parts.length) break;
    }

    return foundLine + 1;
}

const samplePalletData = {
    palletNo: 1,
    palletCount: 3,
    pallet: { boxes: ["abc123", "xyz456"] },
    order: { name: "ZO 123/2021", clientNo: "NumerKlienta"},
    contents: [{ qty: 10, product: { name: "Filtr kasetowy P19-301 klasy G4; 530/500/50", code: "FKA-4/530/500/50 ", featureMap: { wysokosc: 10, szerokosc: 20 } } }]
}

const sampleProductCardData = {
    order: { name: "ZO 123/2021", clientNo: "NumerKlienta"},
    cards: [{ product: {}, materials: [{}], productionDate: new Date(), producingPerson: "Jan Nowak", qualityControlAt: new Date(), qualityControlPerson: "Jan Nowak" }],
    employee: { name: "Michał Kowalski" }
}

export default {
    name: 'PrintTemplateEdit',
    props: {
        templateId: String // w momencie kiedy otwierane w oknie modalnym
    },
    components: {

    },
    data: function () {
        return {
            isLoading: false,
            isSaving: false,
            isChanged: false,
            currentlyUploadedMedium: null,
            isUploadingMedia: false,
            errors: [],
            formats: ['A1', 'A2', 'A3', 'A4', 'A5'],
            dataSchemas: {
                taskBox: 'Karton z produktami',
                pallet: 'Paleta z produktami',
                productsIdsWithQty: 'Produkty z ilościami',
                productQualityCard: 'Karta jakości produktu',
                custom: 'Dowolny'
            },
            argTypes: ['object', 'string', 'number', 'array'],
            template: {},
            samplePalletData,
            sampleProductCardData
        }
    },
    mounted: function() {
    },
    created: async function() {
        this.isLoading = true;
        try {
            this.template = await Api.printing.templates.get(this.templateId || this.$route.params.id);
            this.initEditor();
        } catch (e) {
            console.log(e);
        }
        this.isLoading = false;
    },
    methods: {
        back: function() {
            this.$router.push({ path: `/production/print-templates` });
        },

        initEditor: function () {
            editor = CodeMirror.fromTextArea(document.querySelector('textarea[name="program_code"]'), {
                lineNumbers: true,
                lineWrapping: true,
                theme: 'monokai',
                indentUnit: 4,
                mode: "text/javascript"
            });
            editor.setSize(null, 800);

            editor.setValue(this.template.printFunction || dummyCode);

            editor.on('change', (instance, changeObj) => {
                // this.clearErrors();
                this.isChanged = true;
            });

            editor.setOption('extraKeys', {
                'Ctrl-S': () => {
                    this.save();
                }
            });
        },

        handleMediumFile: function(event) {
            const files = event.target.files;
            if (!files[0]) return;
            if (files[0].type.indexOf('image') != 0) {
                alert("Tylko obrazki!!!");
                return;
            }

            const formData = new FormData();
            formData.append('file', files[0]);

            this.isUploadingMedia = true;
            Api.uploadFile(formData, (progressEvent) => {
                // fObj.progress = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }).then((ob) => {
                this.isUploadingMedia = false;
                if (this.currentlyUploadedMedium != null) {
                    this.currentlyUploadedMedium.url = Api.getFileUrl(ob.fileKey, ob.fileName);
                }
            }).catch((err) => {
                this.isUploadingMedia = false;
                console.error(err);
            });
        },

        save: async function() {
            this.isSaving = true;
            try {
                this.template.printFunction = editor.getValue();
                Api.printing.templates.edit(this.template._id, this.template);
                this.isChanged = false;
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano.' });
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Coś poszło nie tak...' });
            }
            this.isSaving = false;
            this.$emit('close'); // w przypadku kiedy jest to modal
        },

        removeTplArg: function(idx) {
            this.template.arguments.splice(idx, 1);
        },

        addError: function(msg, line) {
            if (line) editor.addLineClass(line - 1, 'background', 'line-error');
            this.errors.push({ line: line, msg: msg });
        },

        /** Czyści błedy z tablicy errors oraz z edytora */
        clearErrors: function() {
            this.errors.forEach(e => {
                if (!e.line) return;
                editor.removeLineClass(e.line - 1, 'background', 'line-error');
            });
            this.errors.length = 0;
        },
        confirmRemove: async function() {
            if (await this.$helpers.confirm('Czy na pewno chcesz usunąć ten szablon?')) {
                this.remove();
            }
        },
        remove: async function() {
            try {
                this.isLoading = true;
                await Api.printing.templates.edit(this.template._id, { isRemoved: true });
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto.' });
                this.isLoading = false;
                this.back();
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystapił błað' });
                this.isLoading = false;
            }
        }
    }
}
</script>
