<template>
    <div id="app" class="app">
        <div v-if="isLogged" id="content" class="app-content box-shadow-z0">
            <app-modal/>

            <Header v-if="isReady"/>
            <div class="app-body">
                <div class="padding" v-if="isReady">
                    <keep-alive include="OrderList">
                        <router-view :key="$route.fullPath"/>
                    </keep-alive>
                </div>
                <div v-else-if="hasError">
                    <ErrorPage />
                </div>
            </div>

            <notifications group="global" :duration="8000" :width="320" position="bottom right">
                <template slot="body" slot-scope="props">
                    <div class="box" :class="[props.item.type]">
                        <div class="box-header">
                            <p class="notifcation-text" v-html="props.item.text"/>
                            <div class="box-tool"><i class="material-icons md-18" @click="props.close">close</i></div>
                        </div>
                    </div>
                </template>
            </notifications>

            <modals-container/>
        </div>
        <div v-if="!isLogged" class="app-content box-shadow-z0" style="overflow: hidden;">
            <Login/>
        </div>
    </div>
</template>

<script>
import UserManager from './UserManager.js'
import SystemNotifications from './SystemNotifications.js'
import Login from './Login.vue'
import ErrorPage from './ErrorPage.vue'
import Header from './components/Header/Header.vue'

export default {
    name: 'app',
    components: {
        Login,
        Header,
        ErrorPage
    },
    data: function () {
        return {
            isLogged: false,
            isReady: false,
            hasError: false
        }
    },
    created: function() {
        this.isLogged = UserManager.isLogged();
        UserManager.events.on('loggedIn', () => {
            this.isLogged = true;
        });
        UserManager.events.on('userReady', () => {
            this.isReady = true;
            this.hasError = false;
            SystemNotifications.enable(this.$router);
        });
        UserManager.events.on('loggedOut', () => {
            this.isLogged = false;
        });
        UserManager.events.on('verifyError', () => {
            this.$h.errorNotif("Nie można połączyć się z serwerem w celu weryfikacji");
            this.hasError = true;
        });
    }
}
</script>

<style lang="scss">
.v-select {
    .vs__dropdown-toggle {
        border-radius: 0px;
        border-color: rgba(120, 130, 140, 0.2);
    }

    .vs__selected {
        border-radius: 0px;
    }
}

@import './assets/styles/animate.min.css';
@import './assets/styles/custom.scss';
@import './assets/styles/select2.css';
@import './assets/styles/bootstrap.min.css';
@import './assets/styles/select2-bootstrap.css';
@import './assets/material-design-icons/material-design-icons.css';
@import './assets/font-awesome/css/font-awesome.css';
@import './assets/styles/app.css';
@import './assets/styles/font.css';
</style>
