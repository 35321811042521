<template>
    <li class="nav-item dropdown none-m-l text-color" v-if="applications.length > 0">
        <a href="" class="btn white b-a no-shadow none-radius btn-xs m-t" data-toggle="dropdown" :class="{ warning: hasDanger }">
            <span class="">Wnioski o urlop</span>
            <span class="label rounded label-sm up" :class="{ pulse: hasDanger, danger: hasDanger, blue: !hasDanger }" style="line-height:0.8rem;">
                {{applications.length}}
            </span>
        </a>
        <div class="dropdown-menu pull-right w-xl animated fadeInUp no-bg no-border no-shadow" v-if="applications.length > 0">
            <div class="scrollable notifications-box">
                <ul class="list-group list-group-gap m-a-0">
                    <li v-for="app in applications" class="notif-item list-group-item white lt box-shadow-z0 b" :key="app._id">
                        <span class="clear block">
                            Oczekujący wniosek o urlop od {{EmployeeManager.getName(app.employeeId)}}
                            <a href="#" class="text-blue" @click.prevent="$router.push({ name: 'employee-details', params: { id: app.employeeId } })">przejdź do karty pracownika</a>
                            <br>
                            <small class="text-muted">{{app.createdAt| moment('from')}}</small>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import SystemSocket from '../../SystemSocket';
import EventBus from '../../event-bus.js';

let SCANINTERVAL = null;
export default {
    name: 'FreeDaysApps',

    props: {
    },
    data: function () {
        return {
            EmployeeManager: EmployeeManager,
            applications: []
        }
    },

    computed: {
        hasDanger() {
            const now = new Date();
            const staleApplication = this.applications.find(app => now - new Date(app.createdAt) > 24 * 3600 * 1000);
            return !!staleApplication;
        }
    },

    async created() {
        await EmployeeManager.getEmployees();
        EventBus.$on("applicationAccepted", this.fetchWaitingApplications);

        await this.fetchWaitingApplications();
        SCANINTERVAL = setInterval(async () => {
            await this.fetchWaitingApplications();
        }, 60 * 1000);
    },

    destroyed() {
        clearInterval(SCANINTERVAL);
        EventBus.$off("applicationAccepted", this.fetchWaitingApplications);
    },

    methods: {

        async fetchWaitingApplications() {
            try {
                const res = await Api.applicationForFreeDays.search({
                    status: "waiting"
                });

                this.applications.splice(0);
                this.applications.push(...res.data);
            } catch (error) {
                
            }
        }
    }
}
</script>

<style scoped lang="scss">
.notifications-box {
  max-height: 400px;
}
.notif-item {
  .seen-btn {
    opacity: 0;
  }
}
.notif-item:hover {
  .seen-btn {
    opacity: 1;
  }
}

.black {
    .seen-btn i {
        color: #eee;
    }
}
.label.up {
    top: 10px;
    left: 10px;
}
</style>
