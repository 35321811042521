<template>
    <div class="app-header white box-shadow" :class="{ yellow: $IS_DEV }">
        <div class="navbar">
            <router-link to="/orders" class="navbar-brand">
                <span class="m-r primary-color">ERP</span>
            </router-link>
            <ul class="nav navbar-nav pull-left text-blue-hover primary-color">
                <!-- <li class="nav-item">
        <router-link class="nav-link" to="/orders">
          <span class="nav-text">Zamówienia</span>
        </router-link>
      </li> -->
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('dashboard.view')">
                    <router-link class="nav-link" to="/dashboards">
                        <i class="material-icons md-18 primary-color">assignment</i>
                    </router-link>
                </li>
                <li class="nav-item dropdown dropdown-submenu">
                    <router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="/orders">
                        <span class="nav-text">Zamówienia</span>
                    </router-link>
                    <ul class="dropdown-menu pull-down text-color" role="menu">
                        <li class="dropdown-item">
                            <router-link to="/production-requests-calendar">Kalendarz produkcjny</router-link>
                        </li>
                        <li class="dropdown-item">
                            <router-link to="/production-soft-deadline-calendar">Kalendarz sprzedażowy</router-link>
                        </li>
                        <li class="dropdown-item" v-if="user != null && UserManager.hasPermission('view.customers')">
                            <router-link to="/customers">Kontrahenci</router-link>
                        </li>
                        <li class="dropdown-item"  v-if="user != null && UserManager.hasPermission('view.shipments')">
                            <router-link to="/shipments">Przesyłki</router-link>
                        </li>
                        <li class="dropdown-item">
                            <router-link to="/production-requests">Zlecenia produkcyjne</router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.settings')">
                    <router-link class="nav-link" to="/settings">
                        <span class="nav-text">Ustawienia</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.production')">
                    <router-link class="nav-link" to="/production">
                        <span class="nav-text">Produkcja</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.stats')">
                    <router-link class="nav-link" to="/statistics">
                        <span class="nav-text">Statystyki</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.raports')">
                    <router-link class="nav-link" to="/raports">
                        <span class="nav-text">Raporty</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.monitoring')">
                    <router-link class="nav-link" to="/monitoring">
                        <span class="nav-text">Monitoring</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.stock')">
                    <router-link class="nav-link" to="/stock">
                        <span class="nav-text">Magazyn</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="user != null && UserManager.hasPermission('view.mds')">
                    <router-link class="nav-link" to="/mds">
                        <span class="nav-text">Dostawy</span>
                    </router-link>
                </li>
            </ul>
            <ProfileSettings v-if="user != null" :userInfo="user"/>
        </div>
    </div>
</template>

<script>
import ProfileSettings from './ProfileSettings.vue'
import UserManager from '../../UserManager.js'

export default {
    name: 'Header',
    components: {
        ProfileSettings
    },
    data: function () {
        return {
            user: UserManager.user,
            UserManager
        }
    },
    created: function() {
        UserManager.events.on('userReady', () => {
            this.user = UserManager.user;
        });
    }
}
</script>
