<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm">Adresy dostawy</h2>
            <div class="m-t-sm custom-divider"></div>
            <div>
            <input class="adress-input m-b m-t-sm" v-model="pCodeSearch" type="text" placeholder="Szukaj..." >
            </div>
            <div class="row">
                <label class="col-sm-6 col-md-6 form-control-label">Preferowana dostawa</label>
                <div class="col-sm-6 col-md-6">
                    <v-select placeholder="Nie wybrano" label="name" v-model="order.deliveryType" :options="deliveryTypes" @input="saveDelivery" />
                </div>
            </div>
            <div class="row">
                <label class="col-sm-6 col-md-6 form-control-label">Preferowany przewoźnik</label>
                <div class="col-sm-6 col-md-6">
                    <v-select placeholder="Nie wybrano" label="name" v-model="order.deliveryCompany" :options="deliveryCompanies" @input="saveDelivery" />
                </div>
            </div>
            <hr class="m-y-sm"/>
            <div class="row">
                <div class="col-sm-12">
                    <label class="md-check">
                        <input type="checkbox" @change="saveDelivery" v-model="order.deliveryIsCod">
                        <i class="blue m-r-xs"/>
                        <span class="m-l-xs">Zamówienie za pobraniem</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body p-t-0" v-if="isEmpty">
            <p class="p-t">
                Brak dodanych adresów dla tego klienta<br>
                <a href="#" class="text-blue text-sm m-t" @click.prevent="addDeliveryAddress">Dodaj pierwszy</a>
            </p>
        </div>
        <div class="box-body p-t-0" v-else>
            <div class="p-y custom-divider" v-for="deliveryAddress in computedDeliveryAddresses" :key="deliveryAddress._id">

                <div class="row m-b-xs" >
                    <span class="col-sm-10"  v-if="deliveryAddress.name">
                        <h6 class="_400">{{deliveryAddress.name}}</h6>
                    </span>
                    <span class="col-sm-2" v-if="deliveryAddress.name">
                        <label class="md-check p-t-xs pull-right">
                            <input type="checkbox" @change="setDeliverAddress(deliveryAddress._id)" v-model="selected" :value="deliveryAddress._id">
                            <i class="blue m-r-xs"/>
                        </label>
                    </span>
                    <span class="col-sm-12" v-if="!deliveryAddress.name">Brak nazwy</span>

                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.street">{{deliveryAddress.street}}</span>
                    <span class="col-sm-12" v-else>Brak ulicy</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.postCode && deliveryAddress.city">
                        {{deliveryAddress.postCode}} {{deliveryAddress.city}}
                        <span v-if ="deliveryAddress.countryCode">({{deliveryAddress.countryCode}})</span>
                    </span>
                    <span class="col-sm-12" v-else>Brak miasta</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.phone">{{deliveryAddress.phone}}</span>
                    <span class="col-sm-12" v-else>Brak telefonu</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.email">{{deliveryAddress.email}}</span>
                    <span class="col-sm-12" v-else>Brak email</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.contactPerson">
                        <small class="primary-color ">Osoba do kontaktu:</small> {{deliveryAddress.contactPerson}}</span>
                    <span class="col-md-12" v-else>Brak osoby do kontaktu</span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import EditDelvieryCustomerModal from '../Customers/EditDelvieryCustomerModal.vue'
import { DELIVERY_TYPES } from '../../Common.js'

export default {
    name: 'Customer',
    props: {
        order: Object
    },
    data: function () {
        return {
            selected: [],
            deliveryTypes: Object.keys(DELIVERY_TYPES),
            deliveryCompanies: ["dpd", "dhl", "fedex", "rhenus", "inpost", "geis", "poczta polska", "suus"],
            pCodeSearch:""
        }
    },
    created: function() {
        if (this.order.deliveryAddressId && this.order.deliveryAddressId.length > 0) this.selected.push(this.order.deliveryAddressId);
    },
    computed: {
        isEmpty: function() {
            return this.order.customer.deliveryAddresses.length == 0;
        },
        computedDeliveryAddresses: function() {
            let adresses = this.order.customer.deliveryAddresses;
            adresses = adresses.filter(adres => adres.name && (adres.name.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1) || adres.street && (adres.street.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.street && (adres.street.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.postCode && (adres.postCode.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.city && (adres.city.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.phone && (adres.phone.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.contactPerson && (adres.contactPerson.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            || adres.email && (adres.email.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            )
            
            return adresses;
        }
    },
    methods: {
        setDeliverAddress: function(addressId) {
            if (this.order.deliveryAddressId == addressId) addressId = null;
            Api.order.setDeliverAddress(this.order._id, addressId).then((order) => {
                this.order.deliveryAddressId = addressId;
                this.selected.length = 0;
                this.selected.push(addressId);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Zapisano adres dostawy dla zlecenia.' });
            });
        },

        saveDelivery: async function() {
            try {
                await Api.order.save(this.order._id, { deliveryType: this.order.deliveryType, deliveryCompany: this.order.deliveryCompany, deliveryIsCod: this.order.deliveryIsCod });
                this.$helpers.success("Pomyślnie zmieniono preferowany rodzaj dostawy");
            } catch (e) {
                this.$helpers.errorNotif();
            }
        },

        addDeliveryAddress: async function() {
            let adress = {
                name: this.order.customer.name,
                street: this.order.customer.street,
                postCode: this.order.customer.postCode,
                city: this.order.customer.city,
                countryCode: this.order.customer.countryCode,
                phone: this.order.customer.phone,
            };
            let res = await this.$modal2.show(EditDelvieryCustomerModal, {
                customer: this.order.customer,
                delivery: adress,
            });
            if(res) this.order.customer = res;
        }
    }
}
</script>
<style scoped lang="scss">
    .adress-input {
    min-height: 1.375rem;
    display: inline-block;
    width: 100%;
    color: #55595c;
    background-color: #fff;
    border: 1px solid #ccc;
    }
</style>
