<template>
    <div v-if="user != null">
        <ul class="nav navbar-nav pull-right" style="max-height: 56px;">
            <li class="nav-item  text-color m-l">
                <router-link to="/system_messages" class="nav-link ">
                    <i class="material-icons md-18 primary-color">mail</i>
                    <span class="label rounded label-sm up blue"  style="line-height:0.8rem;" v-if="msgCount">
                        {{msgCount}}
                    </span>
                </router-link>
            </li>
            <li class="nav-item  text-color m-l">
                <a href="#" class="btn white b-a no-shadow none-radius btn-xs m-t" @click.prevent="openGlobalSearch">
                    <i class="material-icons md-18 primary-color">search</i> Szukaj
                </a>
            </li>

            <FreeDaysApps />

            <NotifySpan :notify="freeDaysNotifis" v-if="1 == 0"/>
            <NotifySpan :notify="notifToConfirm"/>
            <NotifySpan :notify="notifWFirmaRemove"/>
            <NotifySpan :notify="notifWFirmaModify"/>
            <NotifySpan :notify="notifReadyToShip"/>
            <li class="nav-item dropdown text-color m-l">
                <a href="" class="nav-link dropdown-toggle clear" data-toggle="dropdown">
                    <span class="avatar w-32">
                        <gravatar :email="user.email" default-img="identicon"/>
                    </span>
                </a>
                <div class="dropdown-menu pull-right dropdown-menu-scale">
                    <!-- <router-link :to="'settings/user/'+user.userId" class="dropdown-item" v-if="user != null && user.roles.indexOf('admin') != -1">
                    <span>Profil</span>
                </router-link> -->
                    <router-link to="/settings" class="dropdown-item" v-if="user != null && UserManager.hasPermission('view.settings')">
                        <span>Ustawienia</span>
                    </router-link>
                    <div class="dropdown-divider"/>
                    <a class="dropdown-item" @click="signOut()" >Wyloguj się</a>
                </div>

            </li>
        </ul>
        <EmployeeTimer v-if="user != null && user.employeeId != null" :user="user"/>
    </div>
</template>

<script>
import Gravatar from 'vue-gravatar';
import UserManager from '../../UserManager.js'
import Api from '../../Api.js'
import NotifySpan from './NotifySpan.vue'
import EmployeeTimer from './EmployeeTimer.vue'
import GlobalTimerModal from './GlobalTimerModal.vue'
import GlobalSearchModal from '../GlobalSearchModal.vue'
import FreeDaysApps from './FreeDaysApps.vue'

export default {
    name: 'ProfileSettings',
    props: {
        userInfo: Object
    },
    components: {
        Gravatar,
        NotifySpan,
        EmployeeTimer,
        FreeDaysApps
    },
    data: function () {
        return {
            user: null,
            UserManager,
            notifications: UserManager.notifications,
            msgCount: null,
            isSearchOpened: false
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    created: async function() {
        this.user = await Api.user.getOne(this.userInfo.userId);
        this.getSystemMsgCount();
    },
    mounted: function () {
        window.addEventListener('keydown', this.handleKeyUp);
        window.setInterval(() => {
            this.getSystemMsgCount()
        }, 50000)
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        freeDaysNotifis: function () {
            return this.notifications.filter(n => !n.isSeen).filter(n => n.code == 'new_application_freeday_to_confirm').sort(function(a, b) {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },
        notifToConfirm: function () {
            return this.notifications.filter(n => !n.isSeen).filter(n => n.code == 'new_task_to_confirm').sort(function(a, b) {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },
        notifWFirmaRemove: function () {
            return this.notifications.filter(n => !n.isSeen).filter(n => n.code == 'wfirma_order_removed').sort(function(a, b) {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },
        notifWFirmaModify: function () {
            return this.notifications.filter(n => !n.isSeen).filter(n => n.code == 'wfirma_order_edited').sort(function(a, b) {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },
        notifReadyToShip: function () {
            return this.notifications.filter(n => !n.isSeen).filter(n => n.code == 'task_ready_to_ship').sort(function(a, b) {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        }
    },
    methods: {
        async getSystemMsgCount() {
            this.msgCount = await Api.systemMessages.getUnseenCount();
        },
        signOut: async function() {
            let timer = null;
            if (this.user.employeeId) timer = await Api.timer.getEmployeeTimer(this.user.employeeId);
            if (this.user.employeeId && timer) {
                this.$modal.show(GlobalTimerModal, {
                    empId: this.user.employeeId
                }, {
                    draggable: true,
                    clickToClose: false,
                    resizable: true,
                    width: '590px'
                });
            } else {
                UserManager.logOut();
            }
        },

        handleKeyUp(e) {
            if (!this.isSearchOpened && e.ctrlKey && (e.keyCode == 191 || e.key == '/' || e.which == 47)) { // slash /
                e.preventDefault();
                this.openGlobalSearch();
            }
        },

        async openGlobalSearch() {
            this.isSearchOpened = true;
            const res = await this.$modal2.show(GlobalSearchModal, {
                scope: 'all'
            }, { width: '400px' });

            if (res && res.type) {
                if (res.type == 'order') {
                    this.$router.push({ path: `/order/${res.object._id}` });
                } else if (res.type == 'product') {
                    this.$router.push({ name: 'product-details', params: { id: res.object._id } });
                }
            }

            this.isSearchOpened = false;
            // }, { width: '750px' }).then(r => console.log("RES", r));
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
