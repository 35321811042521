<template>
    <div class="m-t">
        <strong>Kartony w przesyłce</strong>
        <div class="form-group row" v-for="(paczka, index) in przesylki" :key="index">
            <label class="col-sm-2 form-control-label">#{{index}} </label>
            <div class="col-sm-3 input-group">
                <input type="text" class="form-control" v-model="paczka.opis" placeholder="Opis">
                <span class="input-group-addon">opis</span>
            </div>
            <div class="col-sm-2 input-group">
                <input type="text" class="form-control" v-model="paczka.gabaryt" placeholder="Gabaryt">
                <span class="input-group-addon text-sm p-a-xs">gabaryt</span>
            </div>

            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.masa" placeholder="kg">
                <span class="input-group-addon text-sm p-a-xs">kg</span>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn white" tabindex="-1" @click="przesylki.splice(index, 1)">Usuń</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Dodaj karton</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel('M', 10)">+ M - 35x30x20, 10kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel('L', 20)">+ L - 45x35x25, 20kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel('XL', 30)">+ XL - 60x50x40, 30 kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel('2XL', 30)">+ 2XL - 100x60x40 , 30 kg</button>
            </div>
        </div>

        <!-- <div class="form-group row" v-if="order.boxes && order.boxes.length">
            <label class="col-sm-2 form-control-label">Pobierz paczki z zamówienia</label>
            <div class="col-sm-2">
                <button type="button" class="btn white" @click="getOrderBoxes">Pobierz ({{ order.boxes.length }})</button>
            </div>
        </div> -->

        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Typ przesyłki</label>
            <div class="col-sm-8">
                <v-select placeholder="Typ przesyłki" v-model="serviceType" :options="serviceTypes" disabled/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Zawartość</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="zawartosc" placeholder="Zawartość">
            </div>
        </div>
        <!-- <div class="form-group row">
            <label class="col-sm-3 form-control-label">Wartości nadawanej przesyłki</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="wartosc" placeholder="Wartość nadawanej przesyłki">
            </div>
        </div> -->
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input v-model="isCod" type="checkbox" class="">
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="ostroznie" type="checkbox" class="">
                        <i class="blue"/> Opcja "Ostrożnie"
                    </label>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row ng-scope" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.amount" @change="parsePrice" placeholder="Kwota pobrania [zl]">
            </div>
        </div>
        <div class="form-group row" v-if="isCod">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Numer konta bankowego do pobrań</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.numer" placeholder="Numer konta bankowego do pobrań">
            </div>
        </div>
        <div class="form-group row" v-if="isCod">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Tytuł przelewu</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.tytulem" placeholder="Tytuł przelewu">
            </div>
        </div>

        <a href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>
    </div>
</template>
<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
import { getBoxSize } from "../../../Common";
export default {
    name: 'CarrierFedex',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            przesylki: [],
            serviceTypes: ['Pocztex24'],
            serviceType: 'Pocztex24',
            zawartosc: "Filtry powietrza",
            isCod: false,
            ostroznie: false,
            cod: { amount: null, tytulem: "Pobranie", numer: this.sender.iban },
            // wartosc: 0
        }
    },
    created: function() {
        if(this.order.deliveryIsCod) this.isCod = true;
        
        if (this.order.clientNo && this.order.clientNo.length > 0) {
            this.zawartosc = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }

        if (this.order.invoiceNo && this.order.invoiceNo.length > 0) {
            this.cod.tytulem = "Pobranie - " + this.order.invoiceNo;
        }

        this.przesylki.push({
            masa: 10,
            gabaryt: "M",
            opis: "Karton #1"
        });

        this.order.cart.forEach(cPos => {
            if (!cPos.qty || !cPos.price) return;
            // order.computed.totalPrice.netto += cPos.qty * cPos.price;
            // this.wartosc += cPos.qty * cPos.price * (1 + cPos.taxRate);
        });

        // this.wartosc = Math.round(this.wartosc * 100) / 100;

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets();
    },
    methods: {
        addNewParcel: function(gabaryt, weight) {
            this.przesylki.push({
                gabaryt: gabaryt,
                masa: weight,
                opis: "Karton #" + (this.przesylki.length + 1)
            });
        },

        getOrderBoxes: function() {
            if (!this.order || !this.order.boxes || !this.order.boxes.length) return this.$h.errorNotif("Brak spakowanych kartonów w zamówieniu");

            this.przesylki.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const gabaryt = getBoxSize(box);
                if (!gabaryt) {
                    return this.$h.errorNotif("Nie można dodać kartonu o kodzie: #" + box.code.toUpperCase() + ". Karton przekracza wymiary.");
                } else {
                    this.przesylki.push({
                        gabaryt: gabaryt,
                        masa: box.mass,
                        opis: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                    });
                }
            }
        },

        parsePrice: function() {
            this.cod.amount = this.cod.amount.replace(/,/g, '.');
        },

        save: async function() {
            this.isProcessing = true;
            try {
                const shipments = await Api.shipments.ppSent(this.getPPData());
                // jeszcze do konca nie wiem czemu metoda sent zwraca tablice
                for (const shipment of shipments) this.$emit('shipment', shipment);
                this.$h.success('Dodano przesyłkę');
            } catch (e) {
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },

        getPPData: function() {
            const data = {
                order: this.order._id,
                przesylki: JSON.parse(JSON.stringify(this.przesylki))
            };

            for (let i = 0; i < data.przesylki.length; i++) {
                const paczka = data.przesylki[i];
                if (data.ostroznie) paczka.ostroznie = true;
                paczka.masa = paczka.masa * 1000;
            }

            data.type = this.serviceType;
            data.zawartosc = this.zawartosc;
            data.adres = this.setReceiver();
            if (this.cod.amount > 0 && this.isCod) {
                data.jestPobranie = true;
                data.pobranie = {};
                data.pobranie.sposobPobrania = "RACHUNEK_BANKOWY";
                data.pobranie.kwotaPobrania = Math.round(this.cod.amount * 100);
                data.pobranie.nrb = this.cod.numer;
                data.pobranie.tytulem = this.cod.tytulem;
            }

            // if (this.wartosc && this.wartosc > 0) {
            //     data.wartosc = parseInt(this.wartosc * 100);
            // }
            return data;
        },
        setSender: function() {
            var pos = this.sender.postcode.replace('-', '');
            const s = {
                company: this.sender.company_name,
                name: this.sender.firstname + " " + this.sender.lastname,
                address: this.sender.street,
                city: this.sender.city,
                postalCode: pos,
                email: this.sender.email,
                countryCode: "PL",
                phone: this.sender.phone
            };

            return s;
        },
        setReceiver: function() {
            var pos = this.recipient.postCode.replace('-', '');
            const r = {
                nazwa: this.recipient.name ? this.recipient.name : "",
                nazwa2: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
                ulica: this.recipient.street,
                miejscowosc: this.recipient.city,
                kodPocztowy: pos,
                kraj: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                mobile: this.recipient.phone ? this.recipient.phone : "",
                email: this.recipient.email ? this.recipient.email : ""
            };

            return r;
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.przesylki.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                const gabaryt = getBoxSize(box);
                if (!gabaryt) {
                    this.$helpers.errorNotif("Nie można dodać kartonu o kodzie: #" + box.code.toUpperCase() + ". Karton przekracza wymiary.");
                } else {
                    this.przesylki.push({
                        gabaryt: gabaryt,
                        masa: box.mass,
                        opis: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                    });
                }
            }
        }
    }
}
</script>
