<template>
    <div class="m-t">
        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Numer zewnętrzny</label>
            <div class="col-sm-4">
                <input class="form-control" type="text" v-model="package.nrZewnetrzny" >
            </div>
            <label class="col-sm-2 form-control-label">Uwagi na zlec. podjęcia</label>
            <div class="col-sm-4">
                <input class="form-control" type="text" v-model="package.uwagiPodjecie" >
            </div>
        </div>
        <!-- <div class="form-group row">
            <label class="col-sm-3 form-control-label">Numer referencyjny 1</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="package.Referencje.nrReferencyjny1" >
            </div>
            <label class="col-sm-3 form-control-label">Numer referencyjny 2</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="package.Referencje.nrReferencyjny2" >
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Numer referencyjny 3</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="package.Referencje.nrReferencyjny3" >
            </div>
            <label class="col-sm-3 form-control-label">Numer referencyjny 4</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="package.Referencje.nrReferencyjny4" >
            </div>
        </div> -->
        <hr>
        <strong>Palety w przesyłce</strong>
        <div class="m-t" v-for="(paczka, index) in parcels" :key="index">
            <div class="row">
                <span class="pull-left m-x _600" style="margin-top:2.3rem;">#{{index+1}} </span>
                <div class="col-sm-3">
                    <label for=""></label>
                    <div class="input-group m-t-sm">
                        <input type="text" class="form-control" v-model="paczka.nazwa" placeholder="Nazwa">
                        <span class="input-group-addon">nazwa</span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <label for=""></label>
                    <div class="input-group m-t-sm">
                        <input type="text" class="form-control" v-model="paczka.opis" placeholder="Opis">
                        <span class="input-group-addon">opis</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Wysokość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.wysokosc" placeholder="Wys [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Szerokość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.szerokosc" placeholder="Szer [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Długość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.dlugosc" placeholder="Głębo [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1 ">
                    <label for="">Waga [kg]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.waga" placeholder="kg">
                        <span class="input-group-addon text-sm p-a-xs">kg</span>
                    </div>
                </div>
                <div class="col-sm-1" style="margin-top:1.8rem;">
                    <button type="button" class="btn white" tabindex="-1" @click="parcels.splice(index, 1)">Usuń</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 input-group m-t m-l-lg">
                    <v-select v-model="paczka.opakowanieSymbol" :options="opakowania" :reduce="p => p.v" @input="changeOpis(paczka)"/>
                </div>
                <div class="col-sm-3 input-group m-t">
                    <label class="md-check">
                        <input v-model="paczka.zwrotOpakowan" type="checkbox" @change="toggleZwrotOpakowan(paczka)">
                        <i class="blue"/> Zwrot opakowań
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row m-t-md">
            <div class="col-sm-12">
                <label class="form-control-label">Dodaj palete</label>
                <button class="btn btn-outline btn-xs rounded b-info text-info m-r" @click.prevent="addNewParcel(1.2,0.8,1.2,50,'EUR')">EUR 120x80x120, 50kg</button>
                <button class="btn btn-outline btn-xs rounded b-info text-info m-r" @click.prevent="addNewParcel(1.2,0.8,1.2,50,'EUR podobna')">EUR podobna 120x80x120, 50kg</button>
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(0.8,0.8,1.5,25,'ROLKA')">Rolka 80x80x150, 25kg</button>
            </div>
        </div>
        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Planowana data podjęcia</label>
            <div class="col-sm-3">
                <div class="input-group">
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger"
                            placeholder="Wybierz date"
                            :value="formatDate(dateOne)"
                        >

                        <AirbnbStyleDatepicker
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'single'"
                            :date-one="dateOne"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @date-one-selected="val => { dateOne = val }"
                        />
                    </div>
                </div>

                <!-- <input type="text" class="form-control" v-model="package.planDataPodjeciaOd" placeholder="Zawartość"> -->
            </div>
            <label class="col-sm-3 form-control-label">Planowana data doręczenia</label>
            <div class="col-sm-3">
                <div class="input-group">
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger-two"
                            placeholder="Wybierz date"
                            :value="formatDate(dateTwo)"
                        >

                        <AirbnbStyleDatepicker
                            :trigger-element-id="'datepicker-trigger-two'"
                            :mode="'single'"
                            :date-one="dateTwo"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @date-one-selected="val => { dateTwo = val }"
                        />
                    </div>
                </div>
                <!-- <input type="text" class="form-control" v-model="package.planDataDoreczeniaOd" placeholder="Zawartość"> -->
            </div>
        </div>
        
        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input v-model="package.cod" @change="toggleCod" type="checkbox" class="">
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="isDocReturn" type="checkbox" class="">
                        <i class="blue"/> Dokumenty zwrotne
                    </label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="jestUbezpieczenie" @change="toggleUbezpieczenie" type="checkbox" class="">
                        <i class="blue"/> Ubezpieczenie</label>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row ng-scope" v-if="package.cod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="Cod.wartoscPobrania" @change="parsePrice" placeholder="Kwota pobrania [zl]">
            </div>
        </div>
        <div class="form-group row ng-scope" v-if="jestUbezpieczenie">
            <label class="col-sm-3 form-control-label">Wartość towaru</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" v-model="Ubezpieczenie.wartoscTowaru" @change="parsePricewartoscTowaru" placeholder="Wartośc towaru [zl]">
            </div>
            <label class="col-sm-3 form-control-label">Kwota ubezpieczenia</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" v-model="Ubezpieczenie.kwotaUbezpieczenia" @change="parsePricekwotaUbezpieczenia" placeholder="Kwota ubezpieczenia [zl]">
            </div>
        </div>
        <a href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>

    </div>
</template>
<script>
import Select2 from '../../Select2.vue';
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
import EventBus from '../../../event-bus.js';
import format from 'date-fns/format';

export default {
    name: 'CarrierRhenus',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Select2,
        Spinner
    },
    data: function () {
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            isLoaded: false,
            isProcessing: false,
            parcels: [],
            package: {
                nrZewnetrzny: "",
                cod: false,
                Paczki: {
                    ReqPaczka: []
                },
                Nadawca: {
                    nadawcaJestZlecajacy: true
                },
                MiejsceZaladunku: {
                    uNadawcy: true,
                    uZlecajacego: false
                },
                MiejsceRozladunku: {
                },
                uwagiPodjecie: ""
            },
            Cod: { wartoscPobrania: 0, walutaPobrania: "PLN", formaPlatnosci:"GOTÓWKA", wartoscOplaty:0, walutaOplaty:"PLN" },
            jestUbezpieczenie: false,
            Ubezpieczenie: { wartoscTowaru: 0, walutaTowaru: "PLN", kwotaUbezpieczenia: 0, walutaUbezpieczenia: "PLN"},
            opakowania: [
                { v: "PAC", label: 'PAC -Paczka' },
                { v: "KPR", label: 'KPR - Koperta' },
                { v: "EPN", label: 'EPN - Europaleta' },
                { v: "POP", label: 'POP - Pół paleta' },
                { v: "PPN", label: 'PPN - Przemysłowa paleta' },
                { v: "EEN", label: 'EEN - Paleta Europodobna' },
                { v: "CLL", label: 'CLL - Colli' },
                { v: "KAR", label: 'KAR - Karton' },
                { v: "ROL", label: 'ROL - Rolka' }
            ],
            isDocReturn: false,
        }
    },
    created: function() {
        if(this.order.deliveryIsCod) this.package.cod = true;
        if (this.order.clientNo && this.order.clientNo.length > 0) {
            this.package.nrZewnetrzny = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }

        this.parcels.push({
            waga: 50,
            wysokosc: 1.2,
            szerokosc: 0.8,
            dlugosc: 1.2,
            nazwa: "Filtry powietrza",
            opis: "Filtry powietrza",
            opakowanieSymbol: "EPN",
            opakowanieOpis: "EPN - Europaleta",
            // opakowanieTowaruOpis: "opakowanieTowaruOpis",
            // opakowanieTowaruSymbol: "opakowanieTowaruSymbol",
            zwrotOpakowan: 0
        });
        
        this.setDates();

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets()
    },
    methods: {
        setDates: function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            this.dateOne = today;

            var tommorow = new Date((new Date()).valueOf() + 1000*3600*24);
            var ddt = String(tommorow.getDate()).padStart(2, '0');
            var mmt = String(tommorow.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyyt = tommorow.getFullYear();
            tommorow = yyyyt + '-' + mmt + '-' + ddt;
            this.dateTwo = tommorow;
        },
        changeOpis: function(paczka) {
            let idx =  this.opakowania.map(o => o.v).indexOf(paczka.opakowanieSymbol);
            if(idx != -1) paczka.opakowanieOpis = this.opakowania[idx].label;
        },
        toggleZwrotOpakowan: function(paczka) {
            // eslint-disable-next-line no-unused-expressions
            paczka.zwrotOpakowan = paczka.zwrotOpakowan ? 1 : 0;
        },
        toggleCod: function() {
            // eslint-disable-next-line no-unused-expressions
            this.package.cod ? !this.package.cod : this.package.cod;
        },
        toggleUbezpieczenie: function() {
            // eslint-disable-next-line no-unused-expressions
            this.jestUbezpieczenie ? !this.jestUbezpieczenie : this.jestUbezpieczenie;
        },
        addNewParcel: function(height, width, depth, weight, name) {
            let opakowanieSymbol = "";
            let opakowanieOpis = "";

            if (name == "EUR") {
                opakowanieSymbol = "EPN";
                opakowanieOpis = "EPN - Europaleta";
            };

            if (name == "EUR podobna") {
                opakowanieSymbol = "EEN";
                opakowanieOpis = "EEN - Paleta Europodobna";
            }
            if (name == "ROLKA") {
                opakowanieSymbol = "ROL";
                opakowanieOpis = "ROL - Rolka";
            }

            this.parcels.push({
                wysokosc: height,
                szerokosc: width,
                dlugosc: depth,
                waga: weight,
                nazwa: "Filtry powietrza",
                opis: "Filtry powietrza",
                opakowanieSymbol: opakowanieSymbol,
                opakowanieOpis: opakowanieOpis,
                zwrotOpakowan: 0
            });
        },
        getOrderBoxes: function() {
            if (!this.order || !this.order.shippingPalletes || !this.order.shippingPalletes.length) { return this.$helpers.errorNotif("Brak spakowanych palet w zamówieniu"); }
            this.parcels.splice(0);
            for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                const box = this.order.shippingPalletes[i];
                this.parcels.push({
                    wysokosc: parseFloat(box.height / 1000).toFixed(2),
                    szerokosc: parseFloat(box.width / 1000).toFixed(2),
                    dlugosc: parseFloat(box.length / 1000).toFixed(2),
                    waga: box.mass,
                    nazwa: "Filtry powietrza",
                    opis: "Filtry powietrza",
                    opakowanieSymbol: "EEN",
                    opakowanieOpis: "EEN - Paleta Europodobna",
                    zwrotOpakowan: 0
                });
            }
        },
        parsePrice: function() {
            this.Cod.wartoscPobrania = this.Cod.wartoscPobrania.replace(/,/g, '.');
        },
        parsePricewartoscTowaru: function() {
            this.Ubezpieczenie.wartoscTowaru = this.Ubezpieczenie.wartoscTowaru.replace(/,/g, '.');
        },
        parsePricekwotaUbezpieczenia: function() {
            this.Ubezpieczenie.kwotaUbezpieczenia = this.Ubezpieczenie.kwotaUbezpieczenia.replace(/,/g, '.');
        },
        validationData(data) {
            if (data.package.Odbiorca.email.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Adres dostawy: Pole email nie może byc puste!' });
                return false;
            }
            if (data.package.Odbiorca.Reprezentant.telefon.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Adres dostawy: Pole telefon nie może byc puste!' });
                return false;
            }
            var regExp = /^[0-9]{3}\-?[0-9]{3}\-?[0-9]{3}$/;
            if (!data.package.Odbiorca.Reprezentant.telefon.match(regExp)) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Adres dostawy: Pole telefon musi być w formacie 999999999 lub 999-999-999.' });
                return false;
            }

            return true;
        },
        save: async function() {
            const data = this.setData();
            // console.log("data", data)
            data.order = this.order._id;
            let isValid = this.validationData(data);
            if (!isValid) return;

            this.isProcessing = true;
            try {
                // po co zwracam tablice jak zawsze jest jedna???
                const shipments = await Api.shipments.rhenusSent(data);
                // cos nowego emit tylko co zwraca ten sned
                for (const shipment of shipments) this.$emit('shipment', shipment);
                 // cos nowego emit tylko co zwraca ten sned
                this.isProcessing = false;
                this.$notify({ group: 'global', type: 'notification-success', text: 'Dodano przesyłkę.' });
            } catch (e) {
                this.isProcessing = false;
                console.log("error", e);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład.' });
            }
        },
        setData: function() {
            const data = { };

            this.package.Zleceniodawca = this.setSender();
            this.package.Odbiorca = this.setReceiver();
            this.package.MiejsceRozladunku = this.setMiesceRozladunku();

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd+"T12:49:08.87083+02:00";

            this.package.dataZlecenia = today;
            this.package.planDataPodjeciaOd = this.dateOne+"T00:00:00";
            this.package.planDataPodjeciaDo = this.dateOne+"T00:00:00";
            this.package.planDataDoreczeniaOd = this.dateTwo+"T00:00:00";
            this.package.planDataDoreczeniaDo = this.dateTwo+"T00:00:00";

            this.package.Paczki.ReqPaczka.splice(0);

            for (let i = 0; i < this.parcels.length; i++) {
                const paczka = this.parcels[i];
                paczka.wysokosc = parseFloat((paczka.wysokosc+"").replace(/,/g, '.'));
                paczka.szerokosc = parseFloat((paczka.szerokosc+"").replace(/,/g, '.'));
                paczka.dlugosc = parseFloat((paczka.dlugosc+"").replace(/,/g, '.'));
                paczka.waga = parseFloat((paczka.waga+"").replace(/,/g, '.'));
                paczka.ilosc = 1;
                this.package.Paczki.ReqPaczka.push(paczka);
                // console.log("this.package.Paczki.ReqPaczk length", this.package.Paczki.ReqPaczka.length)
            }
            
            if (this.package.cod) {
                this.package.Cod = this.Cod;
                this.package.cod = true;
            }
            //dodanie do paczki lokalizacji nadzorujacej
            this.package.lokalizacjaNadz = "DROLDZ";

            if (this.isDocReturn) {
                this.package.Dokumenty = {};
                this.package.Dokumenty.ReqDokumentyWymagane = {};
                this.package.Dokumenty.ReqDokumentyWymagane.opis = "zwrot potwierdzonego dokumentu WZ";
                this.package.Dokumenty.ReqDokumentyWymagane.wymagany = true;
                this.package.Dokumenty.ReqDokumentyWymagane.kod = "WZ";
                this.package.Dokumenty.ReqDokumentyWymagane.ilosc = 1;
                this.package.Dokumenty.ReqDokumentyWymagane.rod = true;
                this.package.Dokumenty.ReqDokumentyWymagane.erod = false;
            }

            if (this.jestUbezpieczenie) {
                this.package.Ubezpieczenie = this.Ubezpieczenie;
            }

            data.package = this.package;

            return data;
        },
        setSender: function() {
            const s = {
                dodaj: false,
                symbol: "MARSS FILTRY",
                nazwa: this.sender.firstname + " " + this.sender.lastname,
                adres: this.sender.street,
                miasto: this.sender.city,
                kodPocztowy: this.sender.postcode,
                email: this.sender.email,
                kraj: "PL",
                Reprezentant: {
                    osoba: this.sender.firstname + " " + this.sender.lastname,
				    telefon: this.sender.phone ? this.sender.phone : ""
                }
            };

            return s;
        },
        setReceiver: function() {
            //ubezpieczyc sie na brak emiala
            const r = {
                odbiorcaJestZlecajacy: false,
                dodaj: false,
                symbol: this.recipient.name,
                nazwa: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
                adres: this.recipient.street,
                kodPocztowy: this.recipient.postCode,
                kraj: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                miasto: this.recipient.city,
                email: this.recipient.email ? this.recipient.email : "",
                Reprezentant: {
                    osoba: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
				    telefon: this.recipient.phone ? this.recipient.phone : ""
                }
            };

            return r;
        },
        setMiesceRozladunku() {
            const r = {
                symbol:this.recipient.name,
                uOdbiorcy: true,
                Reprezentant: {
                    osoba: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
				    telefon: this.recipient.phone ? this.recipient.phone : ""
                }
            };
            return r;
        },
        formatDate(dateOne) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            
            return formattedDates;
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.parcels.length = 0;
            // iteracja w przypadku palet
            for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                const box = this.order.shippingPalletes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.parcels.push({
                    wysokosc: parseFloat(box.height / 1000).toFixed(2),
                    szerokosc: parseFloat(box.width / 1000).toFixed(2),
                    dlugosc: parseFloat(box.length / 1000).toFixed(2),
                    waga: box.mass,
                    nazwa: "Filtry powietrza",
                    opis: "Filtry powietrza",
                    opakowanieSymbol: "EEN",
                    opakowanieOpis: "EEN - Paleta Europodobna",
                    zwrotOpakowan: 0
                });
            }
            if (this.parcels.length > 0) return;
            // iteracja w przypadku kartonow jesli nie ma zadnych palet
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.parcels.push({
                    wysokosc: parseFloat(box.height / 1000).toFixed(2),
                    szerokosc: parseFloat(box.width / 1000).toFixed(2),
                    dlugosc: parseFloat(box.length / 1000).toFixed(2),
                    waga: box.mass,
                    nazwa: "Filtry powietrza",
                    opis: "Filtry powietrza",
                    opakowanieSymbol: "KAR",
                    opakowanieOpis: "KAR - Karton",
                    zwrotOpakowan: 0
                });
            }
            
        }
    }
}
</script>

<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
