<template>
    <div class="box m-b-0 box-modal">
        <div class="box-header">
            <h3>{{ title }}</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div style="min-height:280px;"  class="box-body">
            <form class="form-horizontal">
                <div class="row">
                    <div class="col-md-12 col-xs-12" v-for="(value, key) in schema" :key="'s_'+key">
                        <span v-if="value.type == 'text' || value.type == 'date' || value.type == 'number'" class="text-xs text-muted">{{ value.label }}:</span>
                        <input v-if="value.type == 'text'" class="form-control custom-input m-b" type="text" v-model="inputs[key]">
                        <input v-if="value.type == 'number'" class="form-control custom-input m-b" type="number" v-model.number="inputs[key]">

                        <textarea v-if="value.type == 'textarea'" class="form-control m-b" rows="3" v-model="inputs[key]" :placeholder="value.label"/>

                        <label v-if="value.type == 'checkbox'" class="md-check m-b">
                            <input type="checkbox" v-model="inputs[key]">
                            <i class="blue m-r-xs"/>
                            <span class="m-l-xs">{{ value.label }}</span>
                        </label>

                        <div v-if="value.type=='select_single' || value.type == 'select'">
                            <v-select  :placeholder="value.label" style="width:100%;" v-model="inputs[key]"  :options="value.options" label="label" :reduce="t => t.id"/>
                        </div>
                        <div v-else-if="value.type=='select_multiple'">
                            <v-select multiple="multiple"  :placeholder="value.name" style="width:100%;" v-model="inputs[key]"  :options="value.options" label="label" :reduce="t => t.id"/>
                        </div>

                        <datepicker v-if="value.type == 'date'" :language="pl" :monday-first="true"
                                    :bootstrap-styling="true" :typeable="true" format="yyyy-MM-dd" :placeholder="value.label"
                                    v-model="inputs[key]" input-class="form-control custom-input m-b" style="width: 100%; display: inline-block;" />

                        <p v-if="value.type == 'info'">{{ value.label }}</p>
                    </div>
                </div>
            </form>
        </div>
        <div class="box-footer clearfix">
            <button class="btn white none-radius btn-sm" @click="$emit('close')">Anuluj</button>
            <button class="pull-right btn info none-radius btn-sm" type="button" style="height: 32px;" @click="saveAndExit()">Zapisz</button>
        </div>
    </div>
</template>
<script>

/**
 * Okno modalne ogólnego użytku do zbierania danych gdzie w schema możemy podać jakie pola formularza wyświetlić
 * schema {
 *   name: {
 *      label: "Nazwa czegoś",
 *      type: "text|textarea|checkbox|select|number|info",
 *      options: [] <-- jesli type == select
 *   }
 * }
 */

import { deepCopy } from '../Utils';
import Datepicker from 'vuejs-datepicker';
import pl from 'vuejs-datepicker/dist/locale/translations/pl.js'

export default {
    name: 'PromptModal',
    props: {
        title: String,
        schema: {
            type: Object,
            required: true
        },
        values: {
            type: Object,
            required: false
        }
    },
    components: {
        Datepicker
    },
    data() {
        return {
            pl: pl,
            inputs: {}
        }
    },
    created: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        for (const key in this.values) {
            this.inputs[key] = this.values[key];
        }
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    methods: {

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.$emit('close', deepCopy(this.inputs));
            }
        },

        saveAndExit() {
            this.$emit('close', deepCopy(this.inputs));
        }

    }
}
</script>
