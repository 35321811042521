const countries = [
    { id: "AF", text: "Afganistan" },
    { id: "AL", text: "Albania" },
    { id: "DZ", text: "Algieria" },
    { id: "AD", text: "Andora" },
    { id: "AO", text: "Angola" },
    { id: "AI", text: "Anguilla" },
    { id: "AQ", text: "Antarktyda" },
    { id: "AG", text: "Antigua i Barbuda" },
    { id: "SA", text: "Arabia Saudyjska" },
    { id: "AR", text: "Argentyna" },
    { id: "AM", text: "Armenia" },
    { id: "AW", text: "Aruba" },
    { id: "AU", text: "Australia" },
    { id: "AT", text: "Austria" },
    { id: "AZ", text: "Azerbejdżan" },
    { id: "BS", text: "Bahamy" },
    { id: "BH", text: "Bahrajn" },
    { id: "BD", text: "Bangladesz" },
    { id: "BB", text: "Barbados" },
    { id: "BE", text: "Belgia" },
    { id: "BZ", text: "Belize" },
    { id: "BJ", text: "Benin" },
    { id: "BM", text: "Bermudy" },
    { id: "BT", text: "Bhutan" },
    { id: "BY", text: "Białoruś" },
    { id: "MM", text: "Birma" },
    { id: "BO", text: "Boliwia" },
    { id: "BQ", text: "Bonaire, Sint Eustatius i Saba" },
    { id: "BA", text: "Bośnia i Hercegowina" },
    { id: "BW", text: "Botswana" },
    { id: "BR", text: "Brazylia" },
    { id: "BN", text: "Brunei" },
    { id: "IO", text: "Brytyjskie Terytorium Oceanu Indyjskiego" },
    { id: "VG", text: "Brytyjskie Wyspy Dziewicze" },
    { id: "BG", text: "Bułgaria" },
    { id: "BF", text: "Burkina Faso" },
    { id: "BI", text: "Burundi" },
    { id: "CL", text: "Chile" },
    { id: "CN", text: "Chiny" },
    { id: "HR", text: "Chorwacja" },
    { id: "CW", text: "Curaçao" },
    { id: "CY", text: "Cypr" },
    { id: "TD", text: "Czad" },
    { id: "ME", text: "Czarnogóra" },
    { id: "CZ", text: "Czechy" },
    { id: "UM", text: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych" },
    { id: "DK", text: "Dania" },
    { id: "CD", text: "Demokratyczna Republika Konga" },
    { id: "DM", text: "Dominika" },
    { id: "DO", text: "Dominikana" },
    { id: "DJ", text: "Dżibuti" },
    { id: "EG", text: "Egipt" },
    { id: "EC", text: "Ekwador" },
    { id: "ER", text: "Erytrea" },
    { id: "EE", text: "Estonia" },
    { id: "ET", text: "Etiopia" },
    { id: "FK", text: "Falklandy" },
    { id: "FJ", text: "Fidżi" },
    { id: "PH", text: "Filipiny" },
    { id: "FI", text: "Finlandia" },
    { id: "FR", text: "Francja" },
    { id: "TF", text: "Francuskie Terytoria Południowe i Antarktyczne" },
    { id: "GA", text: "Gabon" },
    { id: "GM", text: "Gambia" },
    { id: "GS", text: "Georgia Południowa i Sandwich Południowy" },
    { id: "GH", text: "Ghana" },
    { id: "GI", text: "Gibraltar" },
    { id: "GR", text: "Grecja" },
    { id: "GD", text: "Grenada" },
    { id: "GL", text: "Grenlandia" },
    { id: "GE", text: "Gruzja" },
    { id: "GU", text: "Guam" },
    { id: "GG", text: "Guernsey" },
    { id: "GF", text: "Gujana Francuska" },
    { id: "GY", text: "Gujana" },
    { id: "GP", text: "Gwadelupa" },
    { id: "GT", text: "Gwatemala" },
    { id: "GW", text: "Gwinea Bissau" },
    { id: "GQ", text: "Gwinea Równikowa" },
    { id: "GN", text: "Gwinea" },
    { id: "HT", text: "Haiti" },
    { id: "ES", text: "Hiszpania" },
    { id: "NL", text: "Holandia" },
    { id: "HN", text: "Honduras" },
    { id: "HK", text: "Hongkong" },
    { id: "IN", text: "Indie" },
    { id: "ID", text: "Indonezja" },
    { id: "IQ", text: "Irak" },
    { id: "IR", text: "Iran" },
    { id: "IE", text: "Irlandia" },
    { id: "IS", text: "Islandia" },
    { id: "IL", text: "Izrael" },
    { id: "JM", text: "Jamajka" },
    { id: "JP", text: "Japonia" },
    { id: "YE", text: "Jemen" },
    { id: "JE", text: "Jersey" },
    { id: "JO", text: "Jordania" },
    { id: "KY", text: "Kajmany" },
    { id: "KH", text: "Kambodża" },
    { id: "CM", text: "Kamerun" },
    { id: "CA", text: "Kanada" },
    { id: "QA", text: "Katar" },
    { id: "KZ", text: "Kazachstan" },
    { id: "KE", text: "Kenia" },
    { id: "KG", text: "Kirgistan" },
    { id: "KI", text: "Kiribati" },
    { id: "CO", text: "Kolumbia" },
    { id: "KM", text: "Komory" },
    { id: "CG", text: "Kongo" },
    { id: "KR", text: "Korea Południowa" },
    { id: "KP", text: "Korea Północna" },
    { id: "CR", text: "Kostaryka" },
    { id: "CU", text: "Kuba" },
    { id: "KW", text: "Kuwejt" },
    { id: "LA", text: "Laos" },
    { id: "LS", text: "Lesotho" },
    { id: "LB", text: "Liban" },
    { id: "LR", text: "Liberia" },
    { id: "LY", text: "Libia" },
    { id: "LI", text: "Liechtenstein" },
    { id: "LT", text: "Litwa" },
    { id: "LU", text: "Luksemburg" },
    { id: "LV", text: "Łotwa" },
    { id: "MK", text: "Macedonia" },
    { id: "MG", text: "Madagaskar" },
    { id: "YT", text: "Majotta" },
    { id: "MO", text: "Makau" },
    { id: "MW", text: "Malawi" },
    { id: "MV", text: "Malediwy" },
    { id: "MY", text: "Malezja" },
    { id: "ML", text: "Mali" },
    { id: "MT", text: "Malta" },
    { id: "MP", text: "Mariany Północne" },
    { id: "MA", text: "Maroko" },
    { id: "MQ", text: "Martynika" },
    { id: "MR", text: "Mauretania" },
    { id: "MU", text: "Mauritius" },
    { id: "MX", text: "Meksyk" },
    { id: "FM", text: "Mikronezja" },
    { id: "MD", text: "Mołdawia" },
    { id: "MC", text: "Monako" },
    { id: "MN", text: "Mongolia" },
    { id: "MS", text: "Montserrat" },
    { id: "MZ", text: "Mozambik" },
    { id: "NA", text: "Namibia" },
    { id: "NR", text: "Nauru" },
    { id: "NP", text: "Nepal" },
    { id: "DE", text: "Niemcy" },
    { id: "NE", text: "Niger" },
    { id: "NG", text: "Nigeria" },
    { id: "NI", text: "Nikaragua" },
    { id: "NU", text: "Niue" },
    { id: "NF", text: "Norfolk" },
    { id: "NO", text: "Norwegia" },
    { id: "NC", text: "Nowa Kaledonia" },
    { id: "NZ", text: "Nowa Zelandia" },
    { id: "OM", text: "Oman" },
    { id: "PK", text: "Pakistan" },
    { id: "PW", text: "Palau" },
    { id: "PS", text: "Palestyna" },
    { id: "PA", text: "Panama" },
    { id: "PG", text: "Papua-Nowa Gwinea" },
    { id: "PY", text: "Paragwaj" },
    { id: "PE", text: "Peru" },
    { id: "PN", text: "Pitcairn" },
    { id: "PF", text: "Polinezja Francuska" },
    { id: "PL", text: "Polska" },
    { id: "PR", text: "Portoryko" },
    { id: "PT", text: "Portugalia" },
    { id: "TW", text: "Tajwan" },
    { id: "ZA", text: "Republika Południowej Afryki" },
    { id: "CF", text: "Republika Środkowoafrykańska" },
    { id: "CV", text: "Republika Zielonego Przylądka" },
    { id: "RE", text: "Reunion" },
    { id: "RU", text: "Rosja" },
    { id: "RO", text: "Rumunia" },
    { id: "RW", text: "Rwanda" },
    { id: "EH", text: "Sahara Zachodnia" },
    { id: "KN", text: "Saint Kitts i Nevis" },
    { id: "LC", text: "Saint Lucia" },
    { id: "VC", text: "Saint Vincent i Grenadyny" },
    { id: "BL", text: "Saint-Barthélemy" },
    { id: "MF", text: "Saint-Martin" },
    { id: "PM", text: "Saint-Pierre i Miquelon" },
    { id: "SV", text: "Salwador" },
    { id: "AS", text: "Samoa Amerykańskie" },
    { id: "WS", text: "Samoa" },
    { id: "SM", text: "San Marino" },
    { id: "SN", text: "Senegal" },
    { id: "RS", text: "Serbia" },
    { id: "SC", text: "Seszele" },
    { id: "SL", text: "Sierra Leone" },
    { id: "SG", text: "Singapur" },
    { id: "SX", text: "Sint Maarten" },
    { id: "SK", text: "Słowacja" },
    { id: "SI", text: "Słowenia" },
    { id: "SO", text: "Somalia" },
    { id: "LK", text: "Sri Lanka" },
    { id: "US", text: "Stany Zjednoczone" },
    { id: "SZ", text: "Suazi" },
    { id: "SD", text: "Sudan" },
    { id: "SR", text: "Surinam" },
    { id: "SJ", text: "Svalbard i Jan Mayen" },
    { id: "SY", text: "Syria" },
    { id: "CH", text: "Szwajcaria" },
    { id: "SE", text: "Szwecja" },
    { id: "TJ", text: "Tadżykistan" },
    { id: "TH", text: "Tajlandia" },
    { id: "TZ", text: "Tanzania" },
    { id: "TL", text: "Timor Wschodni" },
    { id: "TG", text: "Togo" },
    { id: "TK", text: "Tokelau" },
    { id: "TO", text: "Tonga" },
    { id: "TT", text: "Trynidad i Tobago" },
    { id: "TN", text: "Tunezja" },
    { id: "TR", text: "Turcja" },
    { id: "TM", text: "Turkmenistan" },
    { id: "TC", text: "Turks i Caicos" },
    { id: "TV", text: "Tuvalu" },
    { id: "UG", text: "Uganda" },
    { id: "UA", text: "Ukraina" },
    { id: "UY", text: "Urugwaj" },
    { id: "UZ", text: "Uzbekistan" },
    { id: "VU", text: "Vanuatu" },
    { id: "WF", text: "Wallis i Futuna" },
    { id: "VA", text: "Watykan" },
    { id: "VE", text: "Wenezuela" },
    { id: "HU", text: "Węgry" },
    { id: "GB", text: "Wielka Brytania" },
    { id: "VN", text: "Wietnam" },
    { id: "IT", text: "Włochy" },
    { id: "CI", text: "Wybrzeże Kości Słoniowej" },
    { id: "BV", text: "Wyspa Bouveta" },
    { id: "CX", text: "Wyspa Bożego Narodzenia" },
    { id: "IM", text: "Wyspa Man" },
    { id: "SH", text: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha" },
    { id: "AX", text: "Wyspy Alandzkie" },
    { id: "CK", text: "Wyspy Cooka" },
    { id: "VI", text: "Wyspy Dziewicze Stanów Zjednoczonych" },
    { id: "HM", text: "Wyspy Heard i McDonalda" },
    { id: "CC", text: "Wyspy Kokosowe" },
    { id: "MH", text: "Wyspy Marshalla" },
    { id: "FO", text: "Wyspy Owcze" },
    { id: "SB", text: "Wyspy Salomona" },
    { id: "ST", text: "Wyspy Świętego Tomasza i Książęca" },
    { id: "ZM", text: "Zambia" },
    { id: "ZW", text: "Zimbabwe" },
    { id: "AE", text: "Zjednoczone Emiraty Arabskie" }
];

export default countries;

export const INPOST_SIZES = {
    A: { width: 8, height: 38, length: 64, sum: 110 },
    B: { width: 19, height: 38, length: 64, sum: 121 },
    C: { width: 41, height: 38, length: 64, sum: 143 }
}

export function getBoxSize(box) {
    const sizeX = Math.round(box.height / 10);
    const sizeY = Math.round(box.width / 10);
    const sizeZ = Math.round(box.length / 10);
    if (sizeX > 150 || sizeY > 150 || sizeZ > 150) return null;

    const sum = sizeX + sizeY + sizeZ;

    if (sum <= 55) return "XS";
    else if (sum <= 75) return "S";
    else if (sum <= 85) return "M";
    else if (sum <= 105) return "L";
    else if (sum <= 150) return "XL";
    else if (sum <= 250) return "2XL";
    else return null;
};

export const AVAILABLE_PERMISSIONS = [
    { desc: "WSZYSTKO", code: "*" },

    { desc: "Widok panelu widgetow", code: "dashboard.view" },
    { desc: "Możliowść edycji panelu widgetow", code: "dashboard.edit" },

    { desc: "Widok magazynu", code: "view.stock" },
    { desc: "Widok monitoringu", code: "view.monitoring" },
    { desc: "Widok raportów", code: "view.raports" },
    { desc: "Widok statystyk", code: "view.stats" },
    { desc: "Widok ustawień", code: "view.settings" },
    { desc: "Widok ustawień produkcyjnych", code: "view.production" },
    { desc: "Widok systemu MDS", code: "view.mds" },
    { desc: "Widok kontrahentów", code: "view.customers" },
    { desc: "Widok przesyłek", code: "view.shipments" },

    { desc: "Widgety > Lista zamówień", code: "view.widgets.order_list_header" },
    { desc: "Widgety > Karta magazyny", code: "view.widgets.warehouse_list_header" },

    { desc: "Usuwanie zamówień", code: "order.remove" },
    { desc: "Dodawanie pracowników", code: "employees.register" },
    { desc: "Edycja pracowników", code: "employees.edit" },

    { desc: "Urządzenia -> dodawanie", code: "devices.register" },
    { desc: "Urządzenia -> edycja", code: "devices.edit" },

    { desc: "Użytkownicy -> dodawanie", code: "users.register" },
    { desc: "Użytkownicy -> edycja", code: "users.edit" },

    { desc: "Edycja kontrahenta", code: "customer.edit" },
    { desc: "Pobieranie ustawień przesyłek", code: "shipments.config.get" },
]

export const DELIVERY_TYPES = {
    kurier: { name: "Kurier", icon: require("./assets/images/truck.png") },
    paczkomaty: { name: "Paczkomaty", icon: require("./assets/images/pallet.png") },
    paleta: { name: "Kurier", icon: require("./assets/images/pallet1.png") },
    polpaleta: { name: "Półpaleta", icon: require("./assets/images/pallet1.png") },
    dowoz: { name: "Kurier", icon: require("./assets/images/delivery-man.png") },
    brak: { name: "Kurier", icon: require("./assets/images/close.png") },
    'odbior osobisty': { name: "Kurier", icon: require("./assets/images/location.png") },
    'kurier klienta': { name: "Kurier", icon: require("./assets/images/truck2.png") }
}

export const ORDER_STATUSES_MAP = {
    new: 'Nowe',
    'waiting-for-prod': 'Oczekuje na prod.',
    'in-production': 'W produkcji',
    'ready-to-pack': 'Got. do spakowania',
    'ready-to-send': 'Gotowe do wysłania',
    pack: 'Spakowane',
    sent: 'Wysłane',
    cancel: 'Anulowany',
    errored: 'Błędny'
};

export const HOLIDAY_TYPES = [
    { id: 'urlop', text: 'Urlop', color: "blue" },
    // { id: 'wyjazd', text: 'Wyjazd', color: "green" },
    { id: 'macierzynski', text: 'Macierzyński/Tacierzyński', color: "green" },
    { id: 'L4', text: 'L4', color: "yellow" },
    { id: 'urlop_na_zadanie', text: 'Urlop na żądanie', color: "orange" },
    { id: 'urlop_nieusprawiedliwiony', text: 'Urlop nieusprawiedliwiony', color: "red" },
    { id: 'urlop_okolicznosciowy', text: 'Urlop okolicznościowy', color: "purple" },
    { id: 'urlop_bezplatny', text: 'Urlop bezpłatny', color: "light-green" },
    { id: 'urlop_opiekunczy', text: 'Urlop opiekuńczy', color: "primary" },
    { id: 'urlop_pelen_dzien_z_godzin', text: 'Urlop pełen dzień z godzin', color: "teal" }
];

export const MONTHS = ['STYCZEŃ', 'LUTY', 'MARZEC', 'KWIECIEŃ', 'MAJ', 'CZERWIEC', 'LIPIEC', 'SIERPIEŃ', 'WRZESIEŃ', 'PAŹDZIERNIK', 'LISTOPAD', 'GRUDZIEŃ'];

/**
 * Stwierdza czy podany kod produktu jest produktem gotowym (czyli takim zamawianym od
 * innej firmy, a nie kupowanym)
 *
 * @param {string} productCode Full product code FKA-3/120/120/50
 * @returns {boolean}
 */
export function isFiltrGotowy(productCode, features) {
    let isFilterGotowy = false;
    return false; // deprecated
    //     if (typeof productCode != 'string' || productCode.indexOf('-') < 1) return false;

    //     if (productCode.indexOf('FKM-V') == 0) return true;

    //     const code = productCode.split("-")[0].toUpperCase().trim();
    //     if (code == 'FAM' || code == 'FD' || code == 'FPT' || code == 'FA') return true;
    //     else return false;
    // for (const key in features) {
    //     let val = features[key] + "";
    //     val = val.trim().toLowerCase();

    //     if (val == 'dokładny' || val == 'absolutny' || val == 'v-cell' || val == 'kartonowy' || val == 'uszczelka' || val == 'cube' ||
    //     val == 'pet' || val == 'harmonijkowy' || val == 'pp+pet' || val == 'afpro' || val == 'patronowy' || val == 'zehnder' || val == 'minipleat' || val == 'worek-filtracyjny' ||
    //     val == 'węglowy' || val == 'columbus' || val == 'wody' || val == 'ex') isFilterGotowy = true;
    // }

    // return isFilterGotowy;
}

export const DATEPICER_LOCALE = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Zastosuj',
    cancelLabel: 'Anuluj',
    weekLabel: 'W',
    customRangeLabel: 'Przedział czasu',
    daysOfWeek: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pią', 'Sob'],
    monthNames: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    firstDay: 0
}
