import Api from './Api.js';
import EventEmitter from 'events';

let pendingPromise = null;

class EmployeeManager {
    constructor() {
        this.employees = [];
        this._isDataValid = true
    }

    invalidate() {
        this._isDataValid = false;
    }

    getEmployees() {
        if (pendingPromise != null) { return pendingPromise; }

        pendingPromise = new Promise((resolve, reject) => {
            if (this.employees.length && this._isDataValid) {
                setTimeout(() => {
                    pendingPromise = null;
                }, 1);
                resolve(this.employees);
                return;
            }

            Api.emp.getEmployees()
                .then((res) => {
                    this.employees = res.sort((a, b) => a.name.localeCompare(b.name));
                    this._isDataValid = true;
                    resolve(this.employees);
                    pendingPromise = null;
                })
                .catch((reason) => {
                    resolve(this.employees);
                    pendingPromise = null;
                });
        });

        return pendingPromise;
    }

    getName(id) {
        const emp = this.employees.find(emp => emp._id == id);
        return emp ? emp.name : null;
    }

    getEmpName(id) {
        const ids = this.employees.map((emp) => { return emp._id + "" });
        const idx = ids.indexOf(id + "");
        if (idx != -1) {
            return this.employees[idx].name;
        } else {
            return '- - -';
        }
    }
}

const instance = new EmployeeManager();

export default instance;
