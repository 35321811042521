import Vue from 'vue'
import Router from 'vue-router'
import Production from './components/Production/Production.vue'
import Semi from './components/Production/Semi.vue'
import Recipes from './components/Production/Recipes.vue'
import WorkPlaces from './components/Production/WorkPlaces.vue'
import Features from './components/Production/Features.vue'
import Order from './components/Order/Order.vue'
import Settings from './components/Settings/Settings.vue'
import Customers from './components/Customers/Customers.vue'
import CustomerCard from './components/Customers/CustomerCard.vue'
import Product from './components/Product/Product.vue'
import WorkPlace from './components/WorkPlace/WorkPlace.vue'
import PrintTemplatesList from './components/Printing/PrintTemplatesList.vue'
import PrintTemplateEdit from './components/Printing/PrintTemplateEdit.vue'
import Monitoring from './components/Monitoring/Monitoring.vue'
import Shipments from './components/Shipments/Shipments.vue'
import AddShipment from './components/Shipments/AddShipment.vue'
import ProductRealizationAnalysiss from './components/Raports/ProductRealizationAnalysiss.vue'
import Raports from './components/Raports/Raports.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        { path: '/', redirect: '/orders' },
        { path: '/dashboards', component: () => import('@/components/Dashboards/DashboardsPage') },
        { path: '/orders', component: () => import('@/components/OrdersListV2/OrdersList') },
        { path: '/system_messages', component: () => import('@/components/SystemMessages/Main.vue') },
        { path: '/production-requests', name: 'production-requests', component: () => import('@/components/ProductionRequests/ProductionRequests.vue'), meta: { title: "Zlecenia produkcyjne" }, props: true },
        {
            path: '/production',
            component: Production,
            children: [
                { path: '/production', beforeEnter: (to, from, next) => next('/production/product_types') },
                { path: 'employees', component: () => import('@/components/Production/Employees') },
                { path: 'employee_forms', component: () => import('@/components/Production/EmployeeForms/FormsList') },
                { path: 'employee_form/:id', name: 'empform-details', component: () => import('@/components/Production/EmployeeForms/FormDetails') },
                { path: 'archived-employees', component: () => import('@/components/Production/ArchivedEmployees') },
                // { path: 'employee/:id', component: () => import('@/components/Production/EmployeeCard'), name: 'employee-details', meta: { title: "Karta ustawień pracownika" } },
                { path: 'employee/:id', component: () => import('@/components/Production/Employee/Main'), name: 'employee-details', meta: { title: "Karta ustawień pracownika" } },
                { path: 'semi', component: Semi },
                { path: 'recipes', component: Recipes, name: 'recipe-list', meta: { title: "Lista recept" } },
                { path: 'workplaces', component: WorkPlaces },
                { path: 'print-template/:id', component: PrintTemplateEdit, meta: { title: "Edycja szablonu wydruku" } },
                { path: 'print-templates', component: PrintTemplatesList, meta: { title: "Szablony wydruku" } },
                { path: 'features', component: () => import('@/components/Production/FeaturesV3') },
                { path: 'product_types', component: () => import('@/components/Production/ProductTypes') },
                { path: 'product-type/:code', component: () => import('@/components/Production/ProductType/Main.vue'), name: 'product-type-details', meta: { title: "Typ produktu" } },
                { path: 'calendar', component: () => import('@/components/Production/Calendar') },
                // { path: 'buffor', component: () => import('@/components/Production/Buffor/BufforList') },
                { path: 'materials', component: () => import('@/components/Production/Materials') },
                { path: 'semi-category', component: () => import('@/components/Production/SemiProductCategory') },
                { path: 'recipesv2', component: () => import('@/components/ProductRecipe/ProductRecipeV2List') },
                // { path: 'production-materials', component: () => import('@/components/Production/Materials/MaterialsList') }
            ]
        },
        { path: '/order/:id', component: Order, name: 'order-details', meta: { title: "Szczegóły zamówienia" } },
        { path: '/product/:id', component: Product, name: 'product-details', meta: { title: "Szczegóły produktu" } },
        {
            path: '/settings',
            component: Settings,
            children: [
                { path: '/settings', beforeEnter: (to, from, next) => next('/settings/prefernces') },
                { path: 'prefernces', component: () => import('@/components/Settings/Prefernces'), meta: { title: "Ustawienia" } },
                { path: 'users', component: () => import('@/components/Settings/Users'), meta: { title: "Ustawienia - użytkownicy" } },
                { path: 'user/:id', component: () => import('@/components/Settings/UserCard'), name: 'user-details', meta: { title: "Karta ustawień użytkownika" } },
                { path: 'devices', component: () => import('@/components/Settings/DevicesList'), meta: { title: "Ustawienia - urządzenia" } },
                { path: 'shipments', component: () => import('@/components/Settings/ShipmentsSettings'), meta: { title: "Ustawienia - przesyłek" } },
                { path: 'mailer', component: () => import('@/components/Settings/Mailer'), meta: { title: "Ustawienia - wiadomości e-mail" } },
                { path: 'entities', component: () => import('@/components/Settings/Entities'), meta: { title: "Lista encji systemowych" } },
                { path: 'system', component: () => import('@/components/Settings/SystemInfo'), meta: { title: "Informacje systemowe" } },
                { path: 'automation', component: () => import('@/components/Automation/AutomationsList'), meta: { title: "Automatyzacje systemowe" } },
                { path: 'automation/:id', name: 'automation-details', component: () => import('@/components/Automation/AutomationPage'), meta: { title: "Automatyzacje systemowe" } }
            ]
        },
        { path: '/customers', component: Customers },
        { path: '/customers/:id', component: CustomerCard, name: 'customer-details', meta: { title: "Szczegóły kontrahenta" } },
        { path: '/production-requests-calendar', name: 'production-requests-calendar', component: () => import('@/components/ProductionRequests/CalendarV3.vue'), meta: { title: "Kalendarz produkcyjny" }, props: true },
        { path: '/production-soft-deadline-calendar', name: 'production-soft-deadline-calendar', component: () => import('@/components/ProductionRequests/CalendarSoftDeadline.vue'), meta: { title: "Kalendarz sprzedażowy" }, props: true },
        { path: '/recipesv2/', component: () => import('@/components/ProductRecipe/ProductRecipeV2List.vue'), name: 'recipes', meta: { title: "Stare recepty" } },
        { path: '/recipe-v2/:id', component: () => import('@/components/ProductRecipe/ProductRecipeV2.vue'), name: 'recipe-details', meta: { title: "Edycja recepty" } },
        { path: '/recipe-v3/:id', component: () => import('@/components/Production/ProductRecipe/ProductRecipeV3.vue'), name: 'recipe-details-v3', meta: { title: "Edycja recepty" } },
        { path: '/product/:id', component: Product, name: 'product-details', meta: { title: "Szczegóły produktu" } },
        { path: '/workplace/:id', component: WorkPlace },
        {
            path: '/monitoring',
            component: Monitoring,
            children: [
                { path: '/monitoring', beforeEnter: (to, from, next) => next('/monitoring/daily-monitoring') },
                // { path: 'daily-monitoring', component: () => import('@/components/Monitoring/DailyMonitoring.vue') },
                { path: 'daily-monitoring', component: () => import('@/components/Monitoring/DailyMonitoringV2.vue') }
            ]
        },
        {
            path: '/statistics',
            component: () => import('@/components/Statistics/Statistics.vue'),
            children: [
                { path: '/statistics', beforeEnter: (to, from, next) => next('/statistics/v3/product-type-production') },
                { path: 'v3/product-type-production', component: () => import('@/components/Statistics/v3/ProductTypeProduction.vue') }, //new1
                { path: 'v3/employee-prs-by-product-type', component: () => import('@/components/Statistics/v3/EmployeesProductTypePRsPerDay.vue') },//new2
                { path: 'v3/employee-product-types', component: () => import('@/components/Statistics/v3/EmployeeProductTypes.vue') }, //new3
                { path: 'v3/production-time-for-product-type', component: () => import('@/components/Statistics/v3/ProductionTimePerProductType.vue') }, //new4
                { path: 'v3/production-for-product-type', component: () => import('@/components/Statistics/v3/ProductionForProductType.vue') }, //new5
                { path: 'task-definiton', component: () => import('@/components/Statistics/TaskDefPerDay.vue') }, //old1
                { path: 'employee-task', component: () => import('@/components/Statistics/EmployeesTasksPerDay.vue') }, //old2
                { path: 'employee-tasks-definitions', component: () => import('@/components/Statistics/EmployeeTasksDefinitions.vue') },//old 3
            ]
        },
        { path: '/shipments', component: Shipments },
        { path: '/add-shipment/:orderId', name: "add-shipment", component: AddShipment, props: true },
        { path: '/order-pickup/:stockOrderId', name: "order-pickup", component: AddShipment, props: true },
        {
            path: '/raports',
            component: Raports,
            children: [
                { path: '/raports', beforeEnter: (to, from, next) => next('/raports/generator-raports') },
                { path: 'product-realization-analysiss', component: ProductRealizationAnalysiss },
                { path: 'generator-raports', component: () => import('@/components/Raports/RaportsGeneratorV2') },
                { path: 'spreadsheets', component: () => import('@/components/Raports/Spreadsheets/Main.vue') },
                { path: 'spreadsheet/:id', name: "spreadsheet", component: () => import('@/components/Raports/Spreadsheets/SheetEdit.vue') }
            ]
        },
        {
            path: '/stock',
            component: () => import('@/components/Stock/StockPage.vue'),
            children: [
                { path: '/stock', beforeEnter: (to, from, next) => next('/stock/products') },
                { path: 'products', component: () => import('@/components/Stock/Stock.vue') },
                { path: 'stock-warehouses', component: () => import('@/components/Stock/StockWarehouses.vue') },
                { path: 'history', name: "stock-history", component: () => import('@/components/Stock/StockHistory.vue') },
                { path: 'stock-order', component: () => import('@/components/Stock/StockOrder.vue'), name: 'stock-order' },
                { path: 'stock-demand-products', component: () => import('@/components/Stock/StockDemandProductsV3.vue') },
                { path: 'stock-zp-semi-products', component: () => import('@/components/Stock/ProductionReqForSemiProducts.vue') },
                { path: 'buffor', component: () => import('@/components/Stock/Buffor/BufforList') },
                { path: 'production-materials', component: () => import('@/components/Stock/Materials/MaterialsList') },
            ]
        },
        {
            path: '/mds',
            component: () => import('@/components/MDS/MarssDeliveryService.vue'),
            children: [
                { path: '/mds', beforeEnter: (to, from, next) => next('/mds/shipments') },
                { path: 'shipments', component: () => import('@/components/MDS/Shipments.vue') },
                { path: 'plans', component: () => import('@/components/MDS/DeliveryPlans.vue'), name: 'delivery-plan' },
                { path: 'add-plan', component: () => import('@/components/MDS/AddDeliveryPlan.vue') },
                { path: '/edit-plan/:planId', name: "edit-plan", component: () => import('@/components/MDS/AddDeliveryPlan.vue'), props: true },
                { path: 'drivers', component: () => import('@/components/MDS/Drivers.vue') }
            ]
        }
    ]
});

const defaultTitle = "System produkcyjny";
const suffix = "MARSS";

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' | ' + suffix;
    } else {
        document.title = defaultTitle + ' | ' + suffix;
    }
    next();
});

export default router;
