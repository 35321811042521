<template>
<div class="box">
  <div class="box-header p-b-sm p-x-0">
   <h2 class="title-color m-b-sm p-x inline">Adresy dostawy</h2>
       <a href="#" class="pull-right text-blue text-sm p-r" v-on:click.prevent="add"><i class="material-icons md-18 text-blue">add</i></a>
      <div class="m-t-sm custom-divider"></div>
     <div>
      <input class="adress-input m-t-sm" v-model="pCodeSearch" type="text" placeholder="Szukaj..." >
     </div>
  </div>
  <div class="m-a-0 custom-divider"></div>
  <div class="box-body p-t-0">
    <p class="p-t" v-if="isEmpty">Brak dodanych adresów.</p>

    <div class="p-b-lg p-t custom-divider" v-if="!isEmpty && computedDeliveryAddresses.length > 0" v-for="deliveryAddress in computedDeliveryAddresses" :key="deliveryAddress._id">
      <div class="row m-b-xs" >
        <span class=" primary-color col-md-4 col-sm-6">Nazwa: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.name">{{deliveryAddress.name}}</span>
        <span class="col-md-8 col-sm-6" v-else>Brak nazwy</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Ulica: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.street">{{deliveryAddress.street}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Miasto: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.postCode && deliveryAddress.city">
          {{deliveryAddress.postCode}} {{deliveryAddress.city}}
          <span v-if ="deliveryAddress.countryCode">({{deliveryAddress.countryCode}})</span>
        </span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Telefon: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.phone">{{deliveryAddress.phone}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Email: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.email">{{deliveryAddress.email}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Osoba do kontaktu: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.contactPerson">{{deliveryAddress.contactPerson}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <a href="#" class="pull-left text-red text-muted text-sm m-t-sm" v-on:click.prevent="remove(deliveryAddress)">Usuń</a>

      <a href="#" class="pull-right text-blue text-sm m-t-sm" v-on:click.prevent="edit(deliveryAddress)">Zmień dane</a>
      <a href="#" class="text-blue pull-right text-sm m-t-sm p-r" v-on:click.prevent="copy(deliveryAddress)">Powiel dane</a>
    
    </div>

  </div>
</div>
</template>
<script>
import Api from '../../Api.js';
import EditDelvieryCustomerModal from './EditDelvieryCustomerModal.vue';
export default {
  name: 'DelvieryAdresses',
  props: {
    customerData: Object
  },
  components: {
  },
  data: function () {
    return {
      customer: JSON.parse(JSON.stringify(this.customerData)),// deep-copy
      pCodeSearch: "",
    }
  },
  watch: {
    customerData(val) {
        this.customer = JSON.parse(JSON.stringify(val));// deep-copy
    }
  },
  computed: {
    isEmpty: function() {
      return this.customer.deliveryAddresses.length == 0;
    },
    computedDeliveryAddresses: function() {
      let adresses = this.customer.deliveryAddresses;
      adresses = adresses.filter(adres => adres.name && (adres.name.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1) || adres.street && (adres.street.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.street && (adres.street.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.postCode && (adres.postCode.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.city && (adres.city.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.phone && (adres.phone.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.contactPerson && (adres.contactPerson.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      || adres.email && (adres.email.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
      )
    
      return adresses;
  }
  },
  methods: {
    add: async function() {
        let res = await this.$modal2.show(EditDelvieryCustomerModal, {
            customer: this.customer
        });
        if(res) this.$emit('deliveryUpdate', res);
    },
    async edit(adress) {
        let res = await this.$modal2.show(EditDelvieryCustomerModal, {
            delivery: adress,
            customer: this.customer
        });
        if(res) this.$emit('deliveryUpdate', res);
      
    },
    async copy(adressToCopy) {
      //nowy adres to to samo co zmiana tylko ze bez id i wtedy sie dodaje
      let newAdress = {
            name: adressToCopy.name,
            street: adressToCopy.street,
            postCode: adressToCopy.postCode,
            city: adressToCopy.city,
            countryCode: adressToCopy.countryCode,
            phone: adressToCopy.phone,
            email:adressToCopy.email,
            contactPerson:adressToCopy.contactPerson
      };
      let res = await this.$modal2.show(EditDelvieryCustomerModal, {
            delivery: newAdress,
            customer: this.customer
      });
      if(res) this.$emit('deliveryUpdate', res);
    },

    remove: async function (address) {
      if (!await this.$h.confirm('Czy na pewno chesz usunąć adres dostawy?')) return;

      let idx = this.customer.deliveryAddresses.map(d => d._id+"").indexOf(address._id+"");
      if (idx != -1){
         this.customer.deliveryAddresses.splice(idx, 1);
         try {
            let res = await Api.customer.edit(this.customer._id, {deliveryAddresses:this.customer.deliveryAddresses});
            if(res) this.$emit('deliveryUpdate', res);
            //  this.$set(this.customer, "deliveryAddresses", cust.deliveryAddresses);
             this.$helpers.success("Pomyślnie usunięto.");
         } catch (error) {
            this.$h.commonApiError(error);
         }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.adress-input {
  min-height: 1.375rem;
  display: inline-block;
  width: 92%;
  color: #55595c;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-left: 12px;
}


</style>